.EmployeeDetailItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 0;
    border-bottom: 1px solid #DCE2EA;
}

.EmployeeDetailItem:last-child{
    border-bottom: none;
}

.EmployeeDetailItem_Title{
    font-size : 17px;
	line-height : 20px;
	color : #6A6D76;
}

.EmployeeDetailItem_Value{
    font-size : 17px;
	line-height : 20px;
    color : #6A6D76;
    text-align: right;
}

.EmployeeDetailItem_Pill{
    border: 1px solid #D8DDEC;
    border-radius: 22px;
    padding: 7px 13px;
    font-size: 14px;
}

@media screen and (max-width:645px) {

    .EmployeeDetailItem{
        flex-direction: column;
        align-items: flex-start;
    }

    .EmployeeDetailItem p + p{
        margin-top: 16px;
    }

    .EmployeeDetailItem_Title{
        color : #1D1764;
    }

    .EmployeeDetailItem_Value{
        text-align: left;
    }
}