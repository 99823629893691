
.ModalAddEmployee {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.ModalContent {
	padding-top : 43px;
}

.AddANewEmployee_H2 {
	color : #1D1764;
	display : block;
	margin-bottom : 16px;
}

.Display_Text{
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	margin-top:18px;
}

.FormWrapper {
	text-align : left;
	display : block;
	padding-top : 43px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	padding : 43px 0px 30px 0px;
}

.GhostButton {
	min-width : 137px;
}

