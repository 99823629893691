.FileUpload {
    position: relative;
}
.FileUpload_Wrapper {
    display: flex;
    flex-direction: row;
}

.FileUpload_Input_Wrapper{
    position: relative;
    flex-shrink: 0;
}



.FileUpload_Label {
    text-align : center;
	border : #B4AFE1 solid 2px;
	border-radius : 3px;
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
	color : #6A6D76;
	cursor : pointer;
}

.FileUpload_Icon {
    margin-right: 10px;
}

.FileUpload_Input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
}

.FileUpload_Input:focus + .FileUpload_Label{
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: #08B687;
}

.FileUpload_Input + .FileUpload_Error {
    display: none;
}

.FileUpload_Input:required:focus + .FileUpload_Error {
    display: block;
    height: 2px;
    background-color: #ff5656;
}

.FileUpload_Attachments {
    color: #3C4D67;
}

.FileUpload_Attachment {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 10px;
}

.FileUpload_AttachmentName {
	display: block;
    color: #797F8E;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
}

.FileUpload_AttachmentIcon {
	padding: 20px 15px;
	border: 1px solid #DCE2EA;
	border-radius: 2px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
    background-color: #fff;
}

.FileUpload_Delete{
    cursor: pointer;
}

.FileUpload_Uploading{
    opacity: 0.4;
}

@media print, screen and (max-width: 40em){
    .FileUpload_Wrapper{
        flex-direction: column;
    }
}