.ModalEditUser {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
}

.ModalTitle {
	text-align : center;
}

.EditUser_H2 {
	color : #1D1764;
}

.FormWrapper {
	text-align : left;
	display : block;
}

.InputWrapper {
	text-align : left;
	display : block;
	padding : 0px 30px 0px 30px;
}

.SelectInputWrapper {
	text-align : left;
	display : block;
	margin-bottom: 15px;
	padding : 0px 30px 0px 30px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	padding : 0px 30px 30px 30px;
}

.ButtonPrimary {
	min-width : 137px;
}

.Error{
	color: red;
}


.SortcodeInputWrapper{
	text-align : left;
	display : block;
	padding : 0px 30px 0px 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 20px;
}

.SortcodeInput{
	width: 30%;
}
