.Header {
	background-color :white;
	
}

@media screen and (min-width:645px) {
	.Header {
		background-color : #1D1764;
	}
}

.Row {
	text-align : left;
}

.LogoWrapperWrapper {
	text-align : left;
	padding : 18px 12px 19px 12px;
}

.AvatarWrapper {
	padding : 16px;
	position: relative;
	display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Avatar{
	cursor: pointer;
}

.DropdownWrapper{
	width:176px;
	z-index: 999999;
	position: absolute;
	right: 10px;
    top: 64px;
}

.MenuTransparency{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	background: green;
	opacity: 0;
	z-index: 999;
}

.ClientCompanyName{
	color: white;
	margin-right: 20px;
	font-size: 14px;
}