.Date_Picker{
	position: relative;
}
.Label_label {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.Label_input {
	width : 100%;
	height : auto;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 16px 12px 17px 12px;
	font-size : 16px;
	line-height : 19px;
	color : #797F8E;
	margin-bottom: 15px;
}
.Label_input:disabled{
	background-color: #e6e6e6;
	cursor: not-allowed;
}

.Label_input:focus{
	border-color: #00D99F;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
}

.Validation_Wrapper{
	position: absolute;
	right:12px;
	top:26px;
}

.Validation_Inner_Wrapper{
	height: 54px;
    display: flex;
    align-items: center;
}
