.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.ModalTitle {
	text-align : center;
	display : block;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	margin-top:20px;
}

.ButtonPrimary {
	min-width : 159px;
}

.ConfirmationLabel {
	font-weight: bold;
}
