.ButtonPrimary {
	text-align : center;
	border-radius : 3px;
	border: #00D99F solid 2px;
    background: #00D99F;
	padding : 14px ;
	font-size : 17px;
	line-height : 18px;
	color : #FFFFFF;
	cursor : pointer;
	transition: all 0.4s ease;
	min-width: 140px;
}
.ButtonPrimary:hover{
	opacity: 0.8;
	outline: none;
}

.ButtonThin{
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
}

.ButtonPrimary:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}
