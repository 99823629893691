.Textarea_Label {
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.Textarea_Textarea {
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 16px 12px 16px 12px;
	width : 100%;
	resize : none;
	font-size : 16px;
	line-height : 19px;
	color : #797F8E;
}

.Textarea_Textarea:focus{
	border-color: #00D99F;
}

