.Select_Label {
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.Select_Wrapper {
	overflow : hidden;
	position : relative;
}

.Select_Select {
	font-size : 16px;
	line-height : 19px;
	color : #6A6D76;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	border-radius : 3px;
	appearance : none;
	width : 100%;
	height : auto;
	margin-bottom : 0;
	padding : 17px 0 0px 0px;
}

.Select_Icon {
	position : absolute;
	top : 50%;
	right : 0px;
	transform : translateY(-50%);
	pointer-events : none;
}

