.FilterButton {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	cursor: pointer;
	outline: none;
}

.Icon{
	margin-left:8px;
}

.Descending{
	transform: rotate(180deg);
}
