.Navigation {
	background-color : #9399AB;
	box-shadow : 0px 2px 14px 0px rgba(0,0,0,0.10);
	border-radius : 5px;
	padding : 19px 13px;
}

.Navigation_Dashboard {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 24px;
	cursor: pointer;
}

.Navigation_AdminSettings {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 27px;
	cursor: pointer;
}

.Navigation_Logout {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	cursor: pointer;
}

