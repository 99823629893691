.ButtonGhost {
	text-align : center;
	border : #00D99F solid 2px;
	border-radius : 3px;
	padding : 14px;
	font-size : 17px;
	line-height : 18px;
	color : #1D1764;
	cursor : pointer;
	min-width: 140px;
}

.ButtonGhost:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

