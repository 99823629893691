
.Row {
	margin-bottom : 26px;
	text-align : left;
	border-bottom: 1px solid #DAE0EC;
}


.EmployeesWrapperWrapper {
	text-align : left;
	display : block;
}

.EmployeesWrapperWrapper_Employees {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
	padding: 0 16px 16px 16px;
	cursor: pointer;
	border-bottom: 4px solid #F7F9FA;
}

.EmployeesWrapperWrapper_Payroll {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	padding: 0 16px 16px 16px;
	cursor: pointer;
	border-bottom: 4px solid #F7F9FA;
}
.Selected{
	border-bottom: 4px solid #00D99F;
}

@media print, screen and (max-width: 40em){

	.EmployeesWrapperWrapper{
		text-align: center;
	}

	.EmployeesWrapperWrapper_Employees{
		width: 48%;
		margin: 0;
	}
	.EmployeesWrapperWrapper_Payroll{
		width: 48%;
	}
}