.ListHeaderWrapper{
	display: none;
	padding: 10px;
	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}
.MobileListHeaderWrapper{
	padding: 10px;
	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}
.HeaderText{
	line-height: 16px;
}

.ButtonWrapper{
	text-align: right;
	margin-bottom:20px;
}

.ButtonWrapperTop{
	text-align: right;
}


.ButtonWrapperTop Button:last-child{
	margin-left: 20px;
}


.ButtonWrapper Button:last-child{
	margin-left: 20px;
}

.Error{
	text-align: right;
	color: red;
	margin-bottom: 20px;
}

.PaymentDeductionAmount{
	text-align: right;
}

.LoadingWrapper{
	margin-top:	30vh;
}

.EditPayrollWrapper{
	background-color: #e4e9f6;
	width: 100%;
	text-align: right;
	padding: 24px;
	position: fixed;
	bottom: -110px;
	left: 0;
	transition: all 0.5s;
}

.EditActive{
	bottom: 0;
}

.SearchSortWrapper{
	margin-bottom: 20px;
}

@media screen and (min-width:1024px) {
	.SearchSortWrapper{
		margin-bottom: 0px;
	}
}


@media screen and (min-width:1024px) {
	.ListHeaderWrapper{
		display: block;
	}

	.MobileListHeaderWrapper{
		display: none;
	}

	.ButtonWrapperTop{
		position: absolute;
		right: 0;
		top: 0px;
	}
}

@media print {
	.ListHeaderWrapper{
		display: none;
	}

	.MobileListHeaderWrapper{
		display: none;
	}

	.ButtonWrapper{
		display: none;
	}

	.ButtonWrapperTop{
		display: none;
	}

	.EditPayrollWrapper{
		display: none;
	}

	.HideInPrint{
		display: none;
	}
}