.ModalAddMemo {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.ModalTitle {
	text-align : center;
	display : block;
}

.AddSSP_H2 {
	color : #1D1764;
}

.AddSSP_P {
	font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
}

.FormWrapper {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.TextWrapper{
	margin-bottom: 24px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
}

.ButtonGhost {
	min-width : 159px;
}

.ButtonDelete {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.Error{
	color: red;
}
