* {
    box-sizing: border-box;
    border: none;
}

p {
    margin: 0;
}

body {
    background-color: #f7f9ff;
}

/* grid override */
@media print, screen and (max-width: 40em){
    .small-10{
        width:95%;
    }

    .small-offset-1{
        margin-left:2.5%;
    }

    .small-0{
        display: none;
    }

    .row.full-width-with-padding{
        padding: 0;
    }
}

@media print, screen and (max-width: 64em){
    .medium-0{
        display: none;
    }
}

@media print, screen and (min-width: 40em){
    .row.full-width {
        width: 100%;
        max-width: 100%; 
    }
    
    .row.full-width-with-padding {
        width: 100%;
        max-width: 100%; 
        padding: 0 30px;
    }

}


/* modal */

.modal, 
.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal {
  display: none;
  z-index: 1000;
}

.modal_overlay {
  z-index: 1005;
  background-color: rgba(43, 43, 43, 0.36);
}

.modal_content {
  z-index: 1010;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
}

.modal--open {
  display: block;
}


*:focus {
    outline-color: #08B687;
}
button{
  cursor: pointer;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-tooltip-lite {
    background: #FFFFFF;
    color: #6A6D76;
    box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    max-width: 700px !important;
  }
  
  .react-tooltip-lite-arrow {
    border-color: #FFFFFF;
    box-shadow : 0px 12px 12px 0px rgba(0,0,0,0.06);
  }
