.PaymentDeductionWrapper{
    background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius : 3px;
    margin-bottom: 16px;
}

.PaymentDeductionLabelWrapper{
    text-align : left;
    padding: 19px 13px;
}

.PaymentDeduction_Label{
    font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.PaymentDeductionValueWrapper{
    position: relative;
    border-left: 3px solid #F7F9FF;
}

.IconDelete{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 25px;
}

input:disabled{
    background-color: #fff;
}