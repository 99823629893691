.SearchWrapper {
    display: flex;
    position: absolute;
    top: 15px;
    right: 68px;
    z-index: 100;
    cursor: pointer;
}

.SearchInputWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    height: 43px;
    min-width:220px;
}

.SearchInput {
    background: transparent;
    margin: 0;
    height: 26px;
    border: none;
    box-shadow: none;
    outline: none;
    width: 154px;
    color: white;
    font-size: 17px;
    line-height: 18px;
    position: absolute;
    left: 32px;
    top: 7px;
}

.SearchInput::placeholder {
    color: white;
    opacity: 0.5;
}

.SearchText{
    color: white;
    font-size: 17px;
    margin: 0 15px;
    position: absolute;
    left: 12px;
    top: 7px;
    cursor: default;
}


.SearchInput:focus {
	border: none;
  	-webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;
      outline: none;
      color: white;
}

.Error {
    position: absolute;
    top: 50px;
    left: 40px;
    color: #FF6C6C;
    z-index: 10;
    width: 175px;
    font-size: 14px;
    line-height: 16px;
}

.SearchAlphaBackground{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(43, 43, 43, 0.36);
    z-index: 99;
}

.SearchIconWrapper{
    background: #484381;
    border-radius: 22px;
    text-align: right;
    transition: width .5s ease;
    width:42px;
    height:42px;
}

.SearchIconWrapperExtended{
    width: 200px;
}

.SearchIcon{
    padding-top:7px;
    padding-right:4px;
}

.SearchMenuTransparency{
    display: none;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
}

.SearchMenu {
    display: none;
    position: absolute;
    top: 50px;
    left: 26px;
    border-radius: 6px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.12);
    list-style: none outside none;
    width: 260px;
    text-align: left;
    z-index: 1000;
    border-color: #484381;
    overflow: hidden;
}

.SearchMenuHeader{
    padding:20px 17px 12px 17px;
    border-bottom: 1px solid #F4F5F8;
    color: #2E3C50;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
}

.SearchMenuOpen {
    display: block;
}