.Row {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
}

.TextColumn1WrapperWrapper {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.TextColumn1WrapperWrapper_TextColumn1 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	border-bottom: 1px solid #6A6D76;
}

.TextColumn3WrapperWrapper {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.TextColumn3WrapperWrapper_TextColumn3 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.TextColumn4WrapperWrapper {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.TextColumn4WrapperWrapper_TextColumn4 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.TextColumn2WrapperWrapper {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.TextColumn2WrapperWrapper_TextColumn2 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.TextColum5WrapperWrapper {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.TextColum5WrapperWrapper_TextColum5 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

@media print, screen and (max-width: 40em){
	.TextColumn3WrapperWrapper{
		text-align: right;
		padding: 17px 16px 21px 16px;
	}
}
