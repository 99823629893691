.Document {
	background-color: #fff;
	padding: 17px 13px 16px 13px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-right: 2px solid #F8F9FF;
	margin-bottom: 13px;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
}

.DocumentText{
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	cursor: pointer;
}
.DocumentDelete{
	cursor: pointer;
}
