.ModalAddMemo {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.ModalTitle {
	text-align : center;
	display : block;
}

.AddAMemo_H2 {
	color : #1D1764;
}

.FormWrapper {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
}

.ButtonGhost {
	min-width : 159px;
}

.ButtonDelete {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.Error{
	color: red;
}
