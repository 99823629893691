body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    border: none;
}

p {
    margin: 0;
}

body {
    background-color: #f7f9ff;
}

/* grid override */
@media print, screen and (max-width: 40em){
    .small-10{
        width:95%;
    }

    .small-offset-1{
        margin-left:2.5%;
    }

    .small-0{
        display: none;
    }

    .row.full-width-with-padding{
        padding: 0;
    }
}

@media print, screen and (max-width: 64em){
    .medium-0{
        display: none;
    }
}

@media print, screen and (min-width: 40em){
    .row.full-width {
        width: 100%;
        max-width: 100%; 
    }
    
    .row.full-width-with-padding {
        width: 100%;
        max-width: 100%; 
        padding: 0 30px;
    }

}


/* modal */

.modal, 
.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal {
  display: none;
  z-index: 1000;
}

.modal_overlay {
  z-index: 1005;
  background-color: rgba(43, 43, 43, 0.36);
}

.modal_content {
  z-index: 1010;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
}

.modal--open {
  display: block;
}


*:focus {
    outline-color: #08B687;
}
button{
  cursor: pointer;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-tooltip-lite {
    background: #FFFFFF;
    color: #6A6D76;
    box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    max-width: 700px !important;
  }
  
  .react-tooltip-lite-arrow {
    border-color: #FFFFFF;
    box-shadow : 0px 12px 12px 0px rgba(0,0,0,0.06);
  }

.Secure_loader_overlay__2WRJc {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #F7F9FF;
}

.Secure_loader__1Kf2L {
    z-index: 1010;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
}

.Secure_loader_content___Xkku {
    max-width: 100%;
    width: 500px;
    text-align: center;
}

.Secure_loader_graphic__1O52h {
    -webkit-animation: Secure_spin__Dpo4W 4s linear infinite;
            animation: Secure_spin__Dpo4W 4s linear infinite;
}

.Secure_loader_title__1XZvr {
    font-size: 16px;
    color: #fff;
    margin-top: 25px;
}

.Secure_loader_info__2mVqZ {
    font-size: 16px;
    color: #797F8E;
    margin-bottom: 0;
}

.Secure_loader_info__2mVqZ a {
    color: #fff;
}

@-webkit-keyframes Secure_spin__Dpo4W { 
    100% {  
        transform:rotate(360deg);
    }
}

@keyframes Secure_spin__Dpo4W { 
    100% {  
        transform:rotate(360deg);
    }
}

.LoadingIndicator_LoadingIndicator__1aNfY {
	margin-top: 50px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
	background-color: #00D99F;
	border-radius: 50%;
  }

  .LoadingIndicator_LoadingIndicator__1aNfY:after {
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	border: 0px solid #F7F9FF;
	transform: translate(-50%, -50%);
	-webkit-animation: LoadingIndicator_loading__Cjzby 900ms ease-out forwards infinite;
	        animation: LoadingIndicator_loading__Cjzby 900ms ease-out forwards infinite;
  }
  
  @-webkit-keyframes LoadingIndicator_loading__Cjzby {
	0% {
	  border: 0px solid #F7F9FF;
	}
	
	20% {
	  border: 8px solid #F7F9FF;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #F7F9FF;
	  width: 110%;
	  height: 110%;
	}
  }
  
  @keyframes LoadingIndicator_loading__Cjzby {
	0% {
	  border: 0px solid #F7F9FF;
	}
	
	20% {
	  border: 8px solid #F7F9FF;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #F7F9FF;
	  width: 110%;
	  height: 110%;
	}
  }

  .LoadingIndicator_LoadingIndicatorModal__184YD {
	margin-top: 50px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40px;
	height: 40px;
	background-color: #00D99F;
	border-radius: 50%;
  }

  .LoadingIndicator_LoadingIndicatorModal__184YD:after {
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	border: 0px solid #AEAFB3;
	transform: translate(-50%, -50%);
	-webkit-animation: LoadingIndicator_loadingModal__ILY1O 900ms ease-out forwards infinite;
	        animation: LoadingIndicator_loadingModal__ILY1O 900ms ease-out forwards infinite;
  }
  
  @-webkit-keyframes LoadingIndicator_loadingModal__ILY1O {
	0% {
	  border: 0px solid #AEAFB3;
	}
	
	20% {
	  border: 8px solid #AEAFB3;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #AEAFB3;
	  width: 110%;
	  height: 110%;
	}
  }
  
  @keyframes LoadingIndicator_loadingModal__ILY1O {
	0% {
	  border: 0px solid #AEAFB3;
	}
	
	20% {
	  border: 8px solid #AEAFB3;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #AEAFB3;
	  width: 110%;
	  height: 110%;
	}
  }
.HeaderLoggedIn_Header__1Fx3S {
	background-color : #1D1764
}

.HeaderLoggedIn_Row__3Uasz {
	text-align : left;
}

.HeaderLoggedIn_LogoWrapperWrapper__2Eh-o {
	padding : 16px;
	cursor: pointer;
	display: flex;
    align-items: center;
}

.HeaderLoggedIn_AvatarWrapper__1g2cu {
	text-align : right;
	padding : 16px 12px 16px 12px;
}
.HeaderLoggedIn_MenuWrapper__yCR0F{
	position: relative;
}

.HeaderLoggedIn_DropdownWrapper__182G-{
	width:176px;
	z-index: 999999;
	position: absolute;
	right: 10px;
    top: 64px;
}

@media print {
	.HeaderLoggedIn_Header__1Fx3S{
		display: none;
	}
}


.HeaderLoggedIn_ClientCompanyName__3VaB1{
	color: white;
	margin-left: 20px;
	font-size: 14px;
	line-height: 18px;;
	width: 200px;
}
.HeaderDropdownMenu_Header__VjNFq {
	background-color :white;
	
}

@media screen and (min-width:645px) {
	.HeaderDropdownMenu_Header__VjNFq {
		background-color : #1D1764;
	}
}

.HeaderDropdownMenu_Row__3wOIZ {
	text-align : left;
}

.HeaderDropdownMenu_LogoWrapperWrapper___YABa {
	text-align : left;
	padding : 18px 12px 19px 12px;
}

.HeaderDropdownMenu_AvatarWrapper__14LoG {
	padding : 16px;
	position: relative;
	display: flex;
    align-items: center;
    justify-content: flex-end;
}
.HeaderDropdownMenu_Avatar__3sx1U{
	cursor: pointer;
}

.HeaderDropdownMenu_DropdownWrapper__2NhU9{
	width:176px;
	z-index: 999999;
	position: absolute;
	right: 10px;
    top: 64px;
}

.HeaderDropdownMenu_MenuTransparency__1wReR{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	background: green;
	opacity: 0;
	z-index: 999;
}

.HeaderDropdownMenu_ClientCompanyName__3ji-M{
	color: white;
	margin-right: 20px;
	font-size: 14px;
}
.NavigationDropdown_Navigation__1RJt9 {
	background-color : #9399AB;
	box-shadow : 0px 2px 14px 0px rgba(0,0,0,0.10);
	border-radius : 5px;
	padding : 19px 13px;
}

.NavigationDropdown_Navigation_Dashboard__YVLNK {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 24px;
	cursor: pointer;
}

.NavigationDropdown_Navigation_AdminSettings__UkrHl {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 27px;
	cursor: pointer;
}

.NavigationDropdown_Navigation_Logout__mX5Ug {
	text-align : left;
	font-size : 16px;
	line-height : 19px;
	color : #FFFFFF;
	display : block;
	cursor: pointer;
}


.Dashboard_Header__2lUiP {
	text-align : left;
	display : block;
}

.Dashboard_Content__da2cu {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
}

.Dashboard_Row__2BNX0 {
	margin-bottom : 26px;
	text-align : left;
}

.Dashboard_TitleWrapperWrapper__hFD74 {
	text-align : center;
}

.Dashboard_TitleWrapperWrapper_Title__gEg5w {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.Dashboard_EmployeesWrapperWrapper__gjWb_ {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.Dashboard_EmployeesWrapperWrapper_Employees__3PYqE {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.Dashboard_EmployeesWrapperWrapper_Payroll__22W0A {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
}

.Dashboard_RectangleWrapperWrapper__2p-1W {
	text-align : left;
	display : block;
}

.Dashboard_SearchWrapperWrapper__2S7as {
	text-align : left;
}

.Dashboard_ButtonPrimaryWrapperWrapper__2SMEZ {
	text-align : right;
	padding-top: 8px;
}

.Dashboard_ButtonPrimary__1wWX3 {
	min-width : 162px;
}

.Dashboard_PayrollOfApril30WrapperWrapper__2w0nn {
	text-align : left;
}

.Dashboard_PayrollOfApril30WrapperWrapper_PayrollOfApril30__2AgDI {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
}

.Dashboard_SearchResultsHeader__2UdKd {
	text-align : left;
	margin-bottom : 11px;
}

.Dashboard_NameWrapperWrapper__lupQK {
	text-align : left;
}

.Dashboard_NameWrapperWrapper_Name__2ED0- {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.Dashboard_PayrollNoWrapperWrapper__2DB4X {
	text-align : left;
}

.Dashboard_PayrollNoWrapperWrapper_PayrollNo__2iqAV {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.Dashboard_DepartmentWrapperWrapper__riqpz {
	text-align : left;
}

.Dashboard_DepartmentWrapperWrapper_Department__11n6a {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.Dashboard_NiNumberWrapperWrapper__NdPm0 {
	text-align : left;
}

.Dashboard_NiNumberWrapperWrapper_NiNumber__2cfuy {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.Dashboard_StatusWrapperWrapper__1JMGg {
	text-align : left;
}

.Dashboard_StatusWrapperWrapper_Status__2abYR {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.Dashboard_SearchResults__14IU9 {
	display : block;
}


@media print, screen and (max-width: 40em){
	.Dashboard_Content__da2cu {
		padding-top : 30px;
	}
}



.DashboardTabs_Row__2a7S3 {
	margin-bottom : 26px;
	text-align : left;
	border-bottom: 1px solid #DAE0EC;
}


.DashboardTabs_EmployeesWrapperWrapper__3RqH3 {
	text-align : left;
	display : block;
}

.DashboardTabs_EmployeesWrapperWrapper_Employees__3LRtL {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
	padding: 0 16px 16px 16px;
	cursor: pointer;
	border-bottom: 4px solid #F7F9FA;
}

.DashboardTabs_EmployeesWrapperWrapper_Payroll__219y2 {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	padding: 0 16px 16px 16px;
	cursor: pointer;
	border-bottom: 4px solid #F7F9FA;
}
.DashboardTabs_Selected__3_o7m{
	border-bottom: 4px solid #00D99F;
}

@media print, screen and (max-width: 40em){

	.DashboardTabs_EmployeesWrapperWrapper__3RqH3{
		text-align: center;
	}

	.DashboardTabs_EmployeesWrapperWrapper_Employees__3LRtL{
		width: 48%;
		margin: 0;
	}
	.DashboardTabs_EmployeesWrapperWrapper_Payroll__219y2{
		width: 48%;
	}
}
.DashboardEmployees_Header__BlvIo {
	text-align : left;
	display : block;
}

.DashboardEmployees_Content__1oUKo {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
}

.DashboardEmployees_Row__1bthq {
	margin-bottom : 26px;
	text-align : left;
}

.DashboardEmployees_TitleWrapperWrapper__3lJa1 {
	text-align : center;
}

.DashboardEmployees_TitleWrapperWrapper_Title__18j3V {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.DashboardEmployees_EmployeesWrapperWrapper__3vdFl {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.DashboardEmployees_EmployeesWrapperWrapper_Employees__1mrkf {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.DashboardEmployees_EmployeesWrapperWrapper_Payroll__5wv8_ {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
}

.DashboardEmployees_RectangleWrapperWrapper__17nhn {
	text-align : left;
	display : block;
}

.DashboardEmployees_SearchWrapperWrapper__3ggFY {
	text-align : left;
}

.DashboardEmployees_ButtonPrimaryWrapperWrapper__fVbcq {
	text-align : right;
	padding-top: 26px;
	padding-left: 16px;
}

.DashboardEmployees_ButtonPrimary__poLpg {
	min-width : 162px;
	margin-left:16px;
	margin-bottom:17px;
}

.DashboardEmployees_PayrollOfApril30WrapperWrapper__3VH2c {
	text-align : left;
}

.DashboardEmployees_PayrollOfApril30WrapperWrapper_PayrollOfApril30__1FSzM {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
}

.DashboardEmployees_SearchResultsHeader__1JZbB {
	text-align : left;
	margin-bottom : 11px;
}

.DashboardEmployees_NameWrapperWrapper__3LK1v {
	text-align : left;
	margin-left: 16px;
}

.DashboardEmployees_NameWrapperWrapper_Name__3gY5u {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DashboardEmployees_PayrollNoWrapperWrapper__1n3ZT {
	text-align : left;
}

.DashboardEmployees_PayrollNoWrapperWrapper_PayrollNo__38ofl {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DashboardEmployees_DepartmentWrapperWrapper__3Yjqy {
	text-align : left;
	margin-left: 16px;
}

.DashboardEmployees_DepartmentWrapperWrapper_Department__2HAsk {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DashboardEmployees_NiNumberWrapperWrapper__3Dvla {
	text-align : left;
}

.DashboardEmployees_NiNumberWrapperWrapper_NiNumber__33OZh {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DashboardEmployees_StatusWrapperWrapper__ki2PZ {
	text-align : left;
	margin-left: 16px;
}

.DashboardEmployees_StatusWrapperWrapper_Status__2RIJA {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DashboardEmployees_SearchResults__1dQWX {
	display : block;
	width:100%;
	cursor: pointer;
	margin-bottom: 16px;
}
.DashboardEmployees_SearchWrapper__3h0Nv{
	display: flex;
	flex-direction: column;
}

.DashboardEmployees_SearchComponent___xNAJ{
	flex-grow: 2;
}
.DashboardEmployees_LeftEmployeeCheckbox__1M6FE{
	margin: 0 0 29px 16px;
	align-self: flex-end;
}

.DashboardEmployees_LeftEmployeeCheckbox__1M6FE label{
	font-size: 15px;
}

.DashboardEmployees_LeftEmployeeCheckbox__1M6FE span{
	margin-top:0px !important;
}
.DashboardEmployees_LeftEmployeeCheckbox__1M6FE input{
	margin:0 !important;
}

@media print, screen and (min-width: 40em){
	.DashboardEmployees_SearchWrapper__3h0Nv{
		flex-direction: row;
		align-items: flex-end;
	}

	.DashboardEmployees_LeftEmployeeCheckbox__1M6FE{
		align-self: auto;
	}
	.DashboardEmployees_LeftEmployeeCheckbox__1M6FE label{
		width: 80px;
		text-align: right;
	}

	.DashboardEmployees_LeftEmployeeCheckbox__1M6FE span{
		margin-top:10px !important;
	}
	.DashboardEmployees_LeftEmployeeCheckbox__1M6FE input{
		margin:10px 0 0 0 !important;
	}
}


@media print, screen and (max-width: 40em){
	.DashboardEmployees_PayrollNoWrapperWrapper__1n3ZT{
		text-align: right;
		padding-right:16px;
	}

	.DashboardEmployees_ButtonPrimaryWrapperWrapper__fVbcq{
		padding:0;
	}

	.DashboardEmployees_ButtonPrimary__poLpg{
		width:100%;
		margin:0;
	}
}

.ButtonGhost_ButtonGhost__kHg-K {
	text-align : center;
	border : #00D99F solid 2px;
	border-radius : 3px;
	padding : 14px;
	font-size : 17px;
	line-height : 18px;
	color : #1D1764;
	cursor : pointer;
	min-width: 140px;
}

.ButtonGhost_ButtonGhost__kHg-K:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}


.FiveColumnRow_Row__GhC3- {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
}

.FiveColumnRow_TextColumn1WrapperWrapper__1uSDJ {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.FiveColumnRow_TextColumn1WrapperWrapper_TextColumn1__3eyi4 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	border-bottom: 1px solid #6A6D76;
}

.FiveColumnRow_TextColumn3WrapperWrapper__3GmIu {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.FiveColumnRow_TextColumn3WrapperWrapper_TextColumn3__16cAr {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.FiveColumnRow_TextColumn4WrapperWrapper__1Jzqo {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.FiveColumnRow_TextColumn4WrapperWrapper_TextColumn4__cKxcM {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.FiveColumnRow_TextColumn2WrapperWrapper__1_6Fq {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.FiveColumnRow_TextColumn2WrapperWrapper_TextColumn2__2Hddl {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.FiveColumnRow_TextColum5WrapperWrapper__14C0l {
	text-align : left;
	padding : 17px 16px 21px 16px;
}

.FiveColumnRow_TextColum5WrapperWrapper_TextColum5__3RGdr {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

@media print, screen and (max-width: 40em){
	.FiveColumnRow_TextColumn3WrapperWrapper__3GmIu{
		text-align: right;
		padding: 17px 16px 21px 16px;
	}
}

.SeachInput_InputTextWithIcon_Label__14dPB {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.SeachInput_InputTextWithIcon_Wrapper__2kRVM {
	position : relative;
}

.SeachInput_InputTextWithIcon_Input__Tm2Fu {
	width : 100%;
	height : auto;
	text-align : left;
	display : block;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	padding-top : 14px;
	padding-bottom : 14px;
	padding-left : 36px;
	border: 1px solid #DCE2EA;
	border-radius: 3px;
	box-shadow: none;
}

.SeachInput_InputTextWithIcon_Input__Tm2Fu:focus{
	border-color: #00D99F;
}

.SeachInput_InputTextWithIcon_Icon__3n9Bc {
	position : absolute;
	top : 10px;
	left : 7px;
}

@media print {
	.SeachInput_Search__18qXg{
		display: none;
	}
}


.InfiniteScroll_InfiniteScroll__1rRH0{
  height:20px;
  width:100%;
  height: 40px;
  margin-bottom: 40px;
}
.FilterButton_FilterButton__2_B4L {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	cursor: pointer;
	outline: none;
}

.FilterButton_Icon__11Il0{
	margin-left:8px;
}

.FilterButton_Descending__1nibQ{
	transform: rotate(180deg);
}

.FormInputCheckboxRightAligned_Checkbox_Wrapper__1_oh_ {
	display : block;
	position : relative;
	text-align : left;
}

.FormInputCheckboxRightAligned_Checkbox_Label__val9y {
	cursor : pointer;
	float : left;
	font-size : 16px;
	line-height : 16px;
	color : #6A6D76;
	transform : translateY(2px);
}

.FormInputCheckboxRightAligned_Checkbox_Input__3yaz1 {
	position : absolute;
	top : 0;
	right : 0;
	opacity : 0;
	width : 20px;
	height : 20px;
	margin : 0px 9px 0px 0px;
	cursor : pointer;
}

.FormInputCheckboxRightAligned_Checkbox_Input__3yaz1 + .FormInputCheckboxRightAligned_Checkbox_Input_Custom__2pfsb {
	float : left;
	width : 20px;
	height : 20px;
	background-image : url(/static/media/Unchecked.56b4d6c6.svg);
	margin : 0px 0px 0px 9px;
	background-repeat: no-repeat;
}

.FormInputCheckboxRightAligned_Checkbox_Input__3yaz1:checked + .FormInputCheckboxRightAligned_Checkbox_Input_Custom__2pfsb {
	background-image : url(/static/media/Checked.d3dd7321.svg);
	background-repeat: no-repeat;
}


.DashboardPayroll_Header__3TZCH {
	text-align : left;
	display : block;
}

.DashboardPayroll_Content__3H5Us {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
}

.DashboardPayroll_Row__23xgE {
	margin-bottom : 26px;
	text-align : left;
}

.DashboardPayroll_TitleWrapperWrapper__1i8Wb {
	text-align : left;
}

.DashboardPayroll_TitleWrapperWrapper_Title__2eBbe {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.DashboardPayroll_EmployeesWrapperWrapper__3S2hR {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.DashboardPayroll_EmployeesWrapperWrapper_Employees__hUyUG {
	font-size : 17px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.DashboardPayroll_EmployeesWrapperWrapper_Payroll__AA9OC {
	font-size : 17px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
}

.DashboardPayroll_RectangleWrapperWrapper__oZ2QI {
	text-align : left;
	display : block;
	padding : 3px 1px 0px 1px;
}

.DashboardPayroll_ButtonDropdownSmallCopy__ttB1Y {
	text-align : left;
}

.DashboardPayroll_ButtonDropdownSmall__2B2U9 {
	border-radius : 3px;
	background-image : linear-gradient(to right, #00D99F, #23F3BB);
	padding : 15px 25px 15px 25px;
}

.DashboardPayroll_ButtonDropdownSmall_Value__djM3c {
	font-size : 17px;
	font-weight : bold;
	line-height : 20px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 15px;
}

.DashboardPayroll_SearchResultsHeader__16Xk_ {
	text-align : left;
	margin-bottom : 11px;
}

.DashboardPayroll_PayPeriodWrapperWrapper__1QmoH {
	text-align : left;
}

.DashboardPayroll_PayPeriodWrapperWrapper_PayPeriod__27MZ5 {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.DashboardPayroll_StartDateWrapperWrapper__2ake0 {
	text-align : left;
}

.DashboardPayroll_StartDateWrapperWrapper_StartDate__j3REU {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.DashboardPayroll_EndDateWrapperWrapper__G9rIR {
	text-align : left;
}

.DashboardPayroll_EndDateWrapperWrapper_EndDate__ZaAeo {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}



.DashboardPayroll_PaymentDateWrapperWrapper_PaymentDate__1-X9F {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
	
}

.DashboardPayroll_TotalCostWrapperWrapper__2F_2u {
	text-align : left;
}

.DashboardPayroll_TotalCostWrapperWrapper_TotalCost__AfEOU {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.DashboardPayroll_TotalPayWrapperWrapper__1zoI8 {
	text-align : left;
}

.DashboardPayroll_TotalPayWrapperWrapper_TotalPay__M_q-f {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}


.DashboardPayroll_StatusWrapperWrapper_Status__3qKBn {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.DashboardPayroll_ResultsRow__3-GqI {
	display : block;
	cursor: pointer;
}
.DashboardPayroll_ButtonPrimaryDropdownWrapper__1mn8l{
	display: flex;
    flex-direction: row-reverse;
	margin-bottom: 30px;
}


.DashboardPayroll_Row_FlexWrapper__1A0kc{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom:16px;
}

.DashboardPayroll_PayPeriodWrapperWrapper_FlexWrapper__1_ImI{
	flex-basis: 33%;
	margin: 0 8px;
}

.DashboardPayroll_PaymentDateWrapperWrapper_FlexWrapper__2n6X2{
	flex-basis: 33%;
	margin: 0 8px;
	text-align: left;
}

.DashboardPayroll_StatusWrapperWrapper_FlexWrapper__-7Xqb{
	flex-basis: 33%;
	margin: 0 8px;
	text-align: left;
}

.DashboardPayroll_Error__3imuU{
	color: red;
}

.DashboardPayroll_ButtonPrimary__wV2H2 {
	min-width : 137px;
}



@media print, screen and (max-width: 50em){

	.DashboardPayroll_PaymentDateWrapperWrapper_FlexWrapper__2n6X2{
		display: none;
	}

	.DashboardPayroll_PayPeriodWrapperWrapper_FlexWrapper__1_ImI{
		flex-basis: 50%;
	}
	
	
	.DashboardPayroll_StatusWrapperWrapper_FlexWrapper__-7Xqb{
		flex-basis: 50%;
	}

}
.SevenColumnRow_Row__8lnKb {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
	margin-bottom: 16px;
	width:100%;
}
.SevenColumnRow_TextColumnWrapperWrapper__wm-jV {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.SevenColumnRow_ButtonColumnWrapperWrapper__iEPuA{
	padding : 8px 0px;
}

.SevenColumnRow_ButtonTertiaryColumnWrapperWrapper__K-I-9{
	padding : 6px 0px;
}

.SevenColumnRow_TextColumn1WrapperWrapper__2kWKb {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.SevenColumnRow_TextColumnWrapper_Text__1wS_z {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.SevenColumnRow_TextColumnWrapper_TextUnderlined__2eMVk {
	border-bottom:1px solid
}


.SevenColumnRow_Row_FlexWrapper__1oMtG{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
}

.SevenColumnRow_PayPeriodWrapperWrapper_FlexWrapper__uIp2f{
	flex-basis: 33%;
	margin: 0 8px;
}
.SevenColumnRow_PaymentDateWrapperWrapper_FlexWrapper__2N-JX{
	flex-basis: 33%;
	margin: 0 8px;
}
.SevenColumnRow_StatusWrapperWrapper_FlexWrapper__15J1C{
	flex-basis: 33%;
	margin: 0 8px;
}


.SevenColumnRow_OpenPayroll_Btn__36oJO{
	width: 100%;
}

.SevenColumnRow_CopyPayrollButton__1d6YW{
	font-size : 14px;
	line-height : 15px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.SevenColumnRow_ButtonPrimary__9HWuT{
	min-width: 106px;
}

.SevenColumnRow_ButtonTertiary__Hur_s{
	min-width: 106px;
}


@media print, screen and (max-width: 50em){
	.SevenColumnRow_PayPeriodWrapperWrapper_FlexWrapper__uIp2f{
		flex-basis: 50%;
	}
	.SevenColumnRow_PaymentDateWrapperWrapper_FlexWrapper__2N-JX{
		display: none;
	}

	.SevenColumnRow_StatusWrapperWrapper_FlexWrapper__15J1C{
		flex-basis: 50%;
	}


	.SevenColumnRow_ButtonPrimary__9HWuT{
		min-width: 73px;
	}

	.SevenColumnRow_ButtonTertiary__Hur_s{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 73px;
		max-width: 73px;
		display: block;
	}
}


.ButtonPrimary_ButtonPrimary__b9RwJ {
	text-align : center;
	border-radius : 3px;
	border: #00D99F solid 2px;
    background: #00D99F;
	padding : 14px ;
	font-size : 17px;
	line-height : 18px;
	color : #FFFFFF;
	cursor : pointer;
	transition: all 0.4s ease;
	min-width: 140px;
}
.ButtonPrimary_ButtonPrimary__b9RwJ:hover{
	opacity: 0.8;
	outline: none;
}

.ButtonPrimary_ButtonThin__2keIt{
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
}

.ButtonPrimary_ButtonPrimary__b9RwJ:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.ButtonTertiary_ButtonTertiary__14uG5 {
	text-align : center;
	border : #B4AFE1 solid 2px;
	border-radius : 3px;
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
	color : #6A6D76;
	cursor : pointer;
}

.NewPayrollModal_ModalAddMemo__8DZTD {
	background-color : #2B2B2B;
	text-align : left;
}

.NewPayrollModal_ModalWindow__tcAO_ {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.NewPayrollModal_ModalTitle__PVw_o {
	text-align : center;
	display : block;
}

.NewPayrollModal_AddAMemo_H2__1JIAh {
	color : #1D1764;
}

.NewPayrollModal_FormWrapper__2wlO1 {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.NewPayrollModal_ButtonWrapper__3g7Ql {
	text-align : right;
	display : block;
}

.NewPayrollModal_ButtonGhost__3QZw7 {
	min-width : 159px;
}

.NewPayrollModal_ButtonDelete__s4KDS {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.NewPayrollModal_Error__qVkR3{
	color: red;
}

.H2_H2__3FmE6 {
	font-size : 20px;
	line-height : 24px;
	color : #1D1764;
}


.FormTextarea_Textarea_Label__2Z2TI {
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.FormTextarea_Textarea_Textarea__3b6Gz {
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 16px 12px 16px 12px;
	width : 100%;
	resize : none;
	font-size : 16px;
	line-height : 19px;
	color : #797F8E;
}

.FormTextarea_Textarea_Textarea__3b6Gz:focus{
	border-color: #00D99F;
}


.Modal_Dialog__1EEGV {
	position: relative;
	width: 650px;
	max-width: 95vw;
	background-color : #FFFFFF;
	border-radius : 8px;
	padding: 30px;
}

.Modal_DialogLarge__d0vv3 {
	width: 1410px;
	padding-top: 30px;
}

.Modal_DialogMedium__2nvoT {
	width: 1012px;
}

.Modal_Dialog__1EEGV h2 {
	text-align: center;
	margin-bottom: 2em;
}

.Modal_Close__2c-58 {
	position: absolute;
	top: 16px;
	right: 16px;
}

.Modal_Dialog__1EEGV .Modal_SecondaryButton__38843 {
	width: 100%;
	margin-top: 10px;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Wrapper__YKICM {
	overflow : hidden;
	position : relative;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Select__2FIhe {
	font-size : 16px;
	line-height : 21px;
	color : #6A6D76;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	border-radius : 3px;
	-webkit-appearance : none;
	   -moz-appearance : none;
	        appearance : none;
	width : 100%;
	height : auto;
	margin-bottom : 0;
	padding : 17px 0 16px 12px;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Select_Slim__1kAjd {
	padding : 12px 0 13px 12px;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Icon__2UvWY {
	position : absolute;
	top : 50%;
	right : 12px;
	transform : translateY(-50%);
	pointer-events : none;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Select__2FIhe:focus{
	border-color: #00D99F;
}

.FormSelectWithoutLabel_SelectWithoutLabel_Label__3ytPb {
	cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
    display: block;
    margin-bottom: 8px;
}



.LoadingModal_Dialog__FEWRG {
	position: relative;
	width: 650px;
	max-width: 95vw;
	border-radius : 8px;
	padding: 30px;
}

.LoadingModal_DialogLarge__3NTM- {
	width: 1410px;
	padding-top: 30px;
}

.LoadingModal_DialogMedium__327ht {
	width: 1012px;
}

.LoadingModal_Dialog__FEWRG h2 {
	text-align: center;
	margin-bottom: 2em;
}

.LoadingModal_Close__1VFLV {
	position: absolute;
	top: 16px;
	right: 16px;
}

.LoadingModal_Dialog__FEWRG .LoadingModal_SecondaryButton__2_HY0 {
	width: 100%;
	margin-top: 10px;
}


.DashboardModalChooseCompany_ModalAddEmployee__eqUiP {
	background-color : #2B2B2B;
	text-align : left;
}

.DashboardModalChooseCompany_ModalWindow__1q1cW {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.DashboardModalChooseCompany_ModalContent__3pdDC {
	padding-top : 43px;
}

.DashboardModalChooseCompany_AddANewEmployee_H2__38RYq {
	color : #1D1764;
	display : block;
	margin-bottom : 16px;
}

.DashboardModalChooseCompany_Display_Text__28sfC{
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	margin-top:18px;
}

.DashboardModalChooseCompany_FormWrapper__2iPXG {
	text-align : left;
	display : block;
	padding-top : 43px;
}

.DashboardModalChooseCompany_ButtonWrapper__VhtBR {
	text-align : right;
	display : block;
	padding : 43px 0px 30px 0px;
}

.DashboardModalChooseCompany_GhostButton__2f0-b {
	min-width : 137px;
}


.FormInputText_InputText_Label__3ymfO {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.FormInputText_InputText_Wrapper__2hb-v {
	position : relative;
}

.FormInputText_InputText_Input__3VSKk {
	width : 100%;
	height : auto;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 16px 12px 17px 12px;
	font-size : 16px;
	line-height : 21px;
	color : #797F8E;
}

.FormInputText_InputText_Input__3VSKk:focus{
	border-color: #00D99F;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
}

.FormInputText_InputText_ToolTip_Wrapper__1Rdb2 {
	position: absolute;
	right:12px;
	top:0px;
}

.FormInputText_InputText_ToolTip__2etao{
	  cursor: pointer;
}

.FormInputText_InputText_Validation_Wrapper__1VBcj {
	position: absolute;
	right:12px;
	top:0px;
}
.FormInputText_InputText_Validation_Inner_Wrapper__3Udqg{
	height: 54px;
    display: flex;
    align-items: center;
}

.InfoToolTip_InfoToolTipWrapper__1UJpm{
	height: 54px;
    display: flex;
    align-items: center;
}
.CompanySearch_DatabasePathSearchWrapper__wcyCg{
    display: flex;
    flex-direction: column;
}

.CompanySearch_DatabasePathSearchItem__2It7f{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.CompanySearch_DatabasePathSearchText__2aSzz{
    font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
}

.CompanySearch_ResultsWrapper__2ODli{
    max-height: 300px;
    overflow-y: auto;
}
.Modal_Dialog__1TqAf {
	position: relative;
	width: 706px;
	background-color : #FFFFFF;
	border-radius : 8px;
	padding: 20px 40px;
}

.Modal_DialogTicket__1N28n {
	width: 1410px;
	padding-top: 30px;
}

.Modal_DialogBid__1wzPz {
	width: 1012px;
}

.Modal_Dialog__1TqAf h2 {
	text-align: center;
	margin-bottom: 2em;
}

.Modal_Close__2B1PS {
	position: absolute;
	top: 12px;
	right: 12px;
}

.Modal_Dialog__1TqAf .Modal_SecondaryButton__2Ogp9 {
	width: 100%;
	margin-top: 10px;
}

.EmployeePayroll_Header__kSPjH {
	text-align : left;
	display : block;
}

.EmployeePayroll_Content__3-p2x {
	text-align : left;
	display : block;
}

.EmployeePayroll_ContentTop__LZHKl {
	background-color : #1D1764;
	text-align : left;
	display : block;
	position: relative;
}

.EmployeePayroll_Row__32HzL {
	text-align : left;
	margin-bottom : 24px;
}

.EmployeePayroll_PayrollHeader__3U-dd{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 69px;
	margin-top: 40px;
}

.EmployeePayroll_PayrollProgress__1ZZIt {
	width:170px;
	height:170px;
	margin-right: 24px;
	flex-shrink: 0;
}

.EmployeePayroll_PayrollProgressBar__JCmzE{
	display: none;
	height:8px;
	position: absolute;
	bottom:0;
	left:0;
	width: 100%;
}

.EmployeePayroll_PayrollProgressInnerBar__2z-Ae{
	height:8px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	transition: width 0.3s;
}

.EmployeePayroll_stripes__2MAqh {
	color: white;
	background: repeating-linear-gradient(
	  135deg,
	  #2FF9C3,
	  #2FF9C3 5px,
	  #00D99F 5px,
	  #00D99F 10px
	);
  }

.EmployeePayroll_PayrollProgress_Text__1MkyZ{
	font-size : 20px;
	line-height : 32px;
	color : #FFFFFF;
}

.EmployeePayroll_PayrollProgress_Text_Bold__14dfD{
	font-size : 38px;
	line-height : 32px;
	color : #FFFFFF;
}

.EmployeePayroll_PayrollDetails__aaacJ {
	flex-grow: 2;
}

.EmployeePayroll_PayrollDetails_Name__31WKA {
	font-size : 32px;
	line-height : 49px;
	color : #FFFFFF;
	display : block;
}

.EmployeePayroll_PayrollDetails_Date__zCfOc,
.EmployeePayroll_PayrollDetails_NiNumber__2jTdl {
	font-size : 16px;
	line-height : 27px;
	color : #FFFFFF;
	display : block;
}

.EmployeePayroll_ContentBottom_PayrollDetails__1U0uD{
	display: none;
}
.EmployeePayroll_ContentBottom_PayrollDetails_Name__1Caxn {
	font-size : 27px;
	font-weight : bold;
	line-height : 32px;
	color : #1D1764;
	display : block;
}

.EmployeePayroll_ContentBottom_PayrollDetails_Date__1T0Yg {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
	display : block;
}

.EmployeePayroll_PayrollNetPay_NetPayLabel__jgkQC {
	text-align : right;
	font-size : 17px;
	line-height : 27px;
	color : #FFFFFF;
	display : block;
}

.EmployeePayroll_PayrollNetPay_NetPayValue__3J9tH {
	text-align : left;
	font-size : 44px;
	line-height : 52px;
	color : #FFFFFF;
	display : block;
}

.EmployeePayroll_ContentBottom__3m9o6 {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 55px;
}

.EmployeePayroll_PaymentNameWrapperWrapper__M6cT1 {
	text-align : left;
}

.EmployeePayroll_PaymentNameWrapperWrapper_PaymentName__3ZASV {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
}

.EmployeePayroll_HoursNoWrapperWrapper__2cP4w {
	text-align : left;
}

.EmployeePayroll_HoursNoWrapperWrapper_HoursNo__3k1Ov {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
}

.EmployeePayroll_PaymentNameValue__2CDsl {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
}

.EmployeePayroll_HoursWrapperWrapper__b1a87 {
	text-align : left;
	padding: 19px 13px;
}

.EmployeePayroll_HoursWrapperWrapper_Hours__PiPth {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.EmployeePayroll_HoursValueWrapperWrapper__1S1Gg{
	border-left:3px solid #F7F9FF;
	padding: 19px 13px;
}

.EmployeePayroll_ContentBottom_Value__34BoD {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}
.EmployeePayroll_PaymentDeductionWrapper__2klnE{
	padding-top: 20px;
	border-top: 1px solid #DAE0EC;
	border-bottom: 1px solid #DAE0EC;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.EmployeePayroll_FormSelectWithoutLabelWrapperWrapper__L0jYU {
	margin-bottom: 20px;
	width:48%;
}

.EmployeePayroll_AddMemoWrapperWrapper__wMYRd {
	display: flex;
	flex-direction: row;
}
.EmployeePayroll_AddMemoWrapperWrapperItem__pwKPT{
	margin-right: 12px;
}

.EmployeePayroll_AddDocumentWrapperWrapperItem__2J5Fx{
	margin-right: 20px;
}

.EmployeePayroll_AddMemoWrapperWrapper_AddMemo__25o1j {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.EmployeePayroll_RemoveMemo__1DKxP{
	margin-left: 11px;
	cursor: pointer;
}

.EmployeePayroll_ContentBottom_AddDocument__pMLM- {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
}
.EmployeePayroll_ButtonWrapper__Ijj48{
	display: flex;
	flex-direction: row-reverse;
}

.EmployeePayroll_ButtonGhost__30Olm {
	min-width : 137px;
	display : inline-block;
	margin-right : 12px;
}

.EmployeePayroll_ButtonPrimary__JwZ06 {
	min-width : 137px;
	display : inline-block;
	
}

.EmployeePayroll_PaymentHoursWrapper__7ra7v{
	margin-bottom: 16px;
}

.EmployeePayroll_DeductionsWrapper__IZ3MQ{
	margin: 32px 0 16px 0;
}

.EmployeePayroll_Footer__1n4ic{
	display: flex;
	flex-direction: row;
}

.EmployeePayroll_FooterColumn__1n26g{
	flex-basis: 50%;
	margin-bottom: 20px;
}
.EmployeePayroll_FooterColumnFullWidth__3hhkH{
	flex-basis: 100%;
}

.EmployeePayroll_FooterTitle__1-Q6q{
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	margin-bottom: 13px;
}

.EmployeePayroll_FooterItem__3rfyn{
	background-color: #fff;
	padding: 17px 13px 16px 13px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-right: 2px solid #F8F9FF;
	margin-bottom: 13px;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
}

.EmployeePayroll_FooterMemoText__1Bzil{
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	cursor: pointer;
}

.EmployeePayroll_FooterItemText__RKfWN{
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.EmployeePayroll_FooterSMPText__1Npth{
	width:100%;
}

.EmployeePayroll_ButtonTertiary__1GsEI{
	margin-right: 12px;
}


@media print, screen and (max-width: 40em){
	.EmployeePayroll_PayrollHeader__3U-dd{
		flex-direction: column-reverse;
		margin-bottom: 40px;
		align-items: flex-start;
	}
	.EmployeePayroll_PayrollProgress__1ZZIt{
		display: none;
	}

	.EmployeePayroll_PayrollProgressBar__JCmzE{
		display: block;
	}

	.EmployeePayroll_PayrollNetPay_NetPayLabel__jgkQC{
		text-align: left;
	}

	.EmployeePayroll_PayrollPay__2Lr1S{
		
		width: 100%;
	}

	.EmployeePayroll_ContentBottom__3m9o6{
		padding-top: 24px;
	}

	.EmployeePayroll_ContentBottom_PayrollDetails__1U0uD{
		display: block;
	}

	.EmployeePayroll_PaymentDeductionWrapper__2klnE{
		flex-direction: column;
	}
	.EmployeePayroll_FormSelectWithoutLabelWrapperWrapper__L0jYU{
		width: 100%;
	}
	.EmployeePayroll_ButtonPrimary__JwZ06{
		width: 48%;
		min-width: 48%;
	}
	.EmployeePayroll_ButtonGhost__30Olm{
		width: 48%;
		min-width: 48%;
		margin-right : 4%;
	}

	.EmployeePayroll_AddMemoWrapperWrapper__wMYRd{
		flex-direction: column;
	}

	.EmployeePayroll_ButtonTertiary__1GsEI{
		width:100%;
		margin-right: 0px;
		margin-bottom: 12px;
	}

	.EmployeePayroll_Footer__1n4ic{
		flex-direction: column;
	}

}


.PaymentDeduction_PaymentDeductionWrapper__1znz-{
    background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius : 3px;
    margin-bottom: 16px;
}

.PaymentDeduction_PaymentDeductionLabelWrapper__1KGRK{
    text-align : left;
    padding: 19px 13px;
}

.PaymentDeduction_PaymentDeduction_Label__2cjzg{
    font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.PaymentDeduction_PaymentDeductionValueWrapper__1sxFX{
    position: relative;
    border-left: 3px solid #F7F9FF;
}

.PaymentDeduction_IconDelete__2d9Fn{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 25px;
}

input:disabled{
    background-color: #fff;
}
.PaymentDeductionFormInput_InputText_Input__2Bqh8 {
	width : 100%;
	height : auto;
	border : #fff solid 1px;
	background-color : #FFFFFF;
	box-shadow : none;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 19px 13px;
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	margin: 0;
	-webkit-appearance: textfield;
	   -moz-appearance: textfield;
	        appearance: textfield;
}
.PaymentDeductionFormInput_InputText_Input__2Bqh8::-webkit-inner-spin-button {
	-webkit-appearance: none;
	        appearance: none;
	margin: 0;
  }

.PaymentDeductionFormInput_InputText_Input__2Bqh8:focus{
	border-color: #00D99F;
	box-shadow : none;
}

.PaymentDeductionFormInput_InputText_Input_Currency__Z3lZ0{
	padding-left: 29px;
}

.PaymentDeductionFormInput_Currency__1ECeP{
	position: absolute;
	top:20px;
	left:16px;
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}


.EmployeePayrollModalAddMemo_ModalAddMemo__20T70 {
	background-color : #2B2B2B;
	text-align : left;
}

.EmployeePayrollModalAddMemo_ModalWindow__qiaGb {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.EmployeePayrollModalAddMemo_ModalTitle__27qv6 {
	text-align : center;
	display : block;
}

.EmployeePayrollModalAddMemo_AddAMemo_H2__1g2G6 {
	color : #1D1764;
}

.EmployeePayrollModalAddMemo_FormWrapper__2QNxQ {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.EmployeePayrollModalAddMemo_ButtonWrapper__qYOA5 {
	text-align : right;
	display : block;
}

.EmployeePayrollModalAddMemo_ButtonGhost__80xa0 {
	min-width : 159px;
}

.EmployeePayrollModalAddMemo_ButtonDelete___69e_ {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.FileUpload_FileUpload__2B-tM {
    position: relative;
}
.FileUpload_FileUpload_Wrapper__ijh1f {
    display: flex;
    flex-direction: row;
}

.FileUpload_FileUpload_Input_Wrapper__1EuVS{
    position: relative;
    flex-shrink: 0;
}



.FileUpload_FileUpload_Label__3FjvA {
    text-align : center;
	border : #B4AFE1 solid 2px;
	border-radius : 3px;
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
	color : #6A6D76;
	cursor : pointer;
}

.FileUpload_FileUpload_Icon__2-UA- {
    margin-right: 10px;
}

.FileUpload_FileUpload_Input__3niLZ {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
}

.FileUpload_FileUpload_Input__3niLZ:focus + .FileUpload_FileUpload_Label__3FjvA{
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: #08B687;
}

.FileUpload_FileUpload_Input__3niLZ + .FileUpload_FileUpload_Error__3bfkN {
    display: none;
}

.FileUpload_FileUpload_Input__3niLZ:required:focus + .FileUpload_FileUpload_Error__3bfkN {
    display: block;
    height: 2px;
    background-color: #ff5656;
}

.FileUpload_FileUpload_Attachments__221En {
    color: #3C4D67;
}

.FileUpload_FileUpload_Attachment__39Amv {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 10px;
}

.FileUpload_FileUpload_AttachmentName__1pNB4 {
	display: block;
    color: #797F8E;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
}

.FileUpload_FileUpload_AttachmentIcon__1iifz {
	padding: 20px 15px;
	border: 1px solid #DCE2EA;
	border-radius: 2px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
    background-color: #fff;
}

.FileUpload_FileUpload_Delete__3mR7f{
    cursor: pointer;
}

.FileUpload_FileUpload_Uploading__2p-Pg{
    opacity: 0.4;
}

@media print, screen and (max-width: 40em){
    .FileUpload_FileUpload_Wrapper__ijh1f{
        flex-direction: column;
    }
}
.Document_Document__3Ba-Z {
	background-color: #fff;
	padding: 17px 13px 16px 13px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-right: 2px solid #F8F9FF;
	margin-bottom: 13px;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
}

.Document_DocumentText__1LGfR{
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	cursor: pointer;
}
.Document_DocumentDelete__ifa5m{
	cursor: pointer;
}

.EmployeePayrollModalAddSSP_ModalAddMemo__3FmRW {
	background-color : #2B2B2B;
	text-align : left;
}

.EmployeePayrollModalAddSSP_ModalWindow__bq3eA {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.EmployeePayrollModalAddSSP_ModalTitle__27iO0 {
	text-align : center;
	display : block;
}

.EmployeePayrollModalAddSSP_AddSSP_H2__21ZlV {
	color : #1D1764;
}

.EmployeePayrollModalAddSSP_AddSSP_P__34iYT {
	font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
}

.EmployeePayrollModalAddSSP_FormWrapper__2ByHn {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.EmployeePayrollModalAddSSP_TextWrapper__3STXI{
	margin-bottom: 24px;
}

.EmployeePayrollModalAddSSP_ButtonWrapper__F26-r {
	text-align : right;
	display : block;
}

.EmployeePayrollModalAddSSP_ButtonGhost__3z4kz {
	min-width : 159px;
}

.EmployeePayrollModalAddSSP_ButtonDelete__3osWv {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.EmployeePayrollModalAddSSP_Error__343Vm{
	color: red;
}

.DatePicker_Date_Picker__Hqtli{
	position: relative;
}
.DatePicker_Label_label__N8lb- {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.DatePicker_Label_input__3lyBm {
	width : 100%;
	height : auto;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 16px 12px 17px 12px;
	font-size : 16px;
	line-height : 19px;
	color : #797F8E;
	margin-bottom: 15px;
}
.DatePicker_Label_input__3lyBm:disabled{
	background-color: #e6e6e6;
	cursor: not-allowed;
}

.DatePicker_Label_input__3lyBm:focus{
	border-color: #00D99F;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
}

.DatePicker_Validation_Wrapper__Rdqbk{
	position: absolute;
	right:12px;
	top:26px;
}

.DatePicker_Validation_Inner_Wrapper__3FaXN{
	height: 54px;
    display: flex;
    align-items: center;
}

.EmployeePayrollModalAddSMP_ModalAddMemo__2jSky {
	background-color : #2B2B2B;
	text-align : left;
}

.EmployeePayrollModalAddSMP_ModalWindow__3Yj08 {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.EmployeePayrollModalAddSMP_ModalTitle__3GY9A {
	text-align : center;
	display : block;
}

.EmployeePayrollModalAddSMP_AddSSP_H2__2g8xe {
	color : #1D1764;
}

.EmployeePayrollModalAddSMP_AddSSP_P__CALLU {
	font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
}

.EmployeePayrollModalAddSMP_FormWrapper__3ZPLx {
	text-align : left;
	display : block;
	margin-bottom: 24px;
}

.EmployeePayrollModalAddSMP_TextWrapper__1sHyD{
	margin-bottom: 24px;
}

.EmployeePayrollModalAddSMP_CheckboxWrapper__32w67{
	margin-bottom: 11px;
}

.EmployeePayrollModalAddSMP_ButtonWrapper__1iDdA {
	text-align : right;
	display : block;
}

.EmployeePayrollModalAddSMP_ButtonGhost__S52Hm {
	min-width : 159px;
}

.EmployeePayrollModalAddSMP_ButtonDelete__1ru8p {
	margin-right: 16px;
}

textarea:disabled{
	background-color: #fff;
}


.EmployeePayrollModalAddSMP_Error__ZmHHa{
	color: red;
}

.FormInputCheckbox_Checkbox_Wrapper__2yNjN {
	display : block;
	position : relative;
	text-align : left;
	height:20px;
}

.FormInputCheckbox_Checkbox_Label__2Jmty {
	cursor : pointer;
	float : left;
	font-size : 16px;
	line-height : 16px;
	color : #6A6D76;
	transform : translateY(2px);
}

.FormInputCheckbox_Checkbox_Input__2dg29 {
	position : absolute;
	top : 0;
	left : 0;
	opacity : 0;
	width : 20px;
	height : 20px;
	margin : 0px 9px 0px 0px;
	cursor : pointer;
}

.FormInputCheckbox_Checkbox_Input__2dg29 + .FormInputCheckbox_Checkbox_Input_Custom__aLuai {
	float : left;
	width : 20px;
	height : 20px;
	background-image : url(/static/media/Unchecked.56b4d6c6.svg);
	background-repeat: no-repeat;
	margin : 0px 9px 0px 0px;
}

.FormInputCheckbox_Checkbox_Input__2dg29:checked + .FormInputCheckbox_Checkbox_Input_Custom__aLuai {
	background-image : url(/static/media/Checked.d3dd7321.svg);
}


.EmployeePayrollSearch_SearchWrapper__1B05f {
    display: flex;
    position: absolute;
    top: 15px;
    right: 68px;
    z-index: 100;
    cursor: pointer;
}

.EmployeePayrollSearch_SearchInputWrapper__3f6Q8 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    height: 43px;
    min-width:220px;
}

.EmployeePayrollSearch_SearchInput__2NYwS {
    background: transparent;
    margin: 0;
    height: 26px;
    border: none;
    box-shadow: none;
    outline: none;
    width: 154px;
    color: white;
    font-size: 17px;
    line-height: 18px;
    position: absolute;
    left: 32px;
    top: 7px;
}

.EmployeePayrollSearch_SearchInput__2NYwS::-webkit-input-placeholder {
    color: white;
    opacity: 0.5;
}

.EmployeePayrollSearch_SearchInput__2NYwS:-ms-input-placeholder {
    color: white;
    opacity: 0.5;
}

.EmployeePayrollSearch_SearchInput__2NYwS::-ms-input-placeholder {
    color: white;
    opacity: 0.5;
}

.EmployeePayrollSearch_SearchInput__2NYwS::placeholder {
    color: white;
    opacity: 0.5;
}

.EmployeePayrollSearch_SearchText__1DS3s{
    color: white;
    font-size: 17px;
    margin: 0 15px;
    position: absolute;
    left: 12px;
    top: 7px;
    cursor: default;
}


.EmployeePayrollSearch_SearchInput__2NYwS:focus {
	border: none;
   box-shadow: none;
      background: transparent;
      outline: none;
      color: white;
}

.EmployeePayrollSearch_Error__3zyMj {
    position: absolute;
    top: 50px;
    left: 40px;
    color: #FF6C6C;
    z-index: 10;
    width: 175px;
    font-size: 14px;
    line-height: 16px;
}

.EmployeePayrollSearch_SearchAlphaBackground__1tnAJ{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(43, 43, 43, 0.36);
    z-index: 99;
}

.EmployeePayrollSearch_SearchIconWrapper__3R4dy{
    background: #484381;
    border-radius: 22px;
    text-align: right;
    transition: width .5s ease;
    width:42px;
    height:42px;
}

.EmployeePayrollSearch_SearchIconWrapperExtended__27Iu4{
    width: 200px;
}

.EmployeePayrollSearch_SearchIcon__3Lo7T{
    padding-top:7px;
    padding-right:4px;
}

.EmployeePayrollSearch_SearchMenuTransparency__3bUw8{
    display: none;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
}

.EmployeePayrollSearch_SearchMenu__Hj1Yd {
    display: none;
    position: absolute;
    top: 50px;
    left: 26px;
    border-radius: 6px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.12);
    list-style: none outside none;
    width: 260px;
    text-align: left;
    z-index: 1000;
    border-color: #484381;
    overflow: hidden;
}

.EmployeePayrollSearch_SearchMenuHeader__3scth{
    padding:20px 17px 12px 17px;
    border-bottom: 1px solid #F4F5F8;
    color: #2E3C50;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
}

.EmployeePayrollSearch_SearchMenuOpen__1ZN3u {
    display: block;
}
.EmployeePayrollSearchResultItem_SearchResultItem__7vpFj{
    padding:17px;
    border-bottom: 1px solid #b7bdc6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.EmployeePayrollSearchResultItem_SearchResultItem__7vpFj:hover {
    background-color: #3B3670;
    transition: background-color 200ms linear;
}

.EmployeePayrollSearchResultItem_SearchResultItem__7vpFj:last-of-type{
    border-bottom: none;
}

.EmployeePayrollSearchResultItem_SearchResultItemName__1rdPY{
    color: #FFFFFF;
    font-size: 17px;	
    font-weight: 500;	
    line-height: 19px;
    margin: 0;
    flex-basis: 20%;
}

.EmployeePayrollSearchResultItem_SearchResultItemLocation__1Muzx{
    color: #B7BDC6;
    font-size: 15px;	
    font-weight: 500;	
    line-height: 19px;
    margin: 0;
}

.EmployeePayrollSearchResultItem_SearchResultLabel__2OAw4{
    color: #B7BDC6;
    font-size: 15px;	
    font-weight: 600;	
    line-height: 23px;
}

.EmployeePayrollSearchResultItem_SearchResultValue__3GgVX{
    color: #3C4D67;	
    font-size: 24px;	
    line-height: 35px;
}

.EmployeePayrollSearchResultItem_SearchResultItemAboutYou__3pZKu{
    color: #797F8E;
    font-size: 15px;	
    line-height: 23px;
    margin: 0;
    flex-basis: 50%;
}
.EmployeePayrollSearchResultItem_SearchResultVertAlignBaseline__3JcS_{
    align-self: baseline
}
.EmployeePayrollSearchResult_ResultsList__32bNT {
    background-color: #484381;
}
.EmployeeDetails_Header__Vsm7X {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.EmployeeDetails_Content__3Tr2z {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 40px;
}

.EmployeeDetails_Row__2lgXt {
	margin-bottom : 60px;
}

.EmployeeDetails_Details__3_Px_{
	margin-bottom: 80px;
}

.EmployeeDetails_HeaderTitleWrapper__1rrAG {
	margin-bottom : 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.EmployeeDetails_HeaderTitleWrapper__1rrAG {
		flex-direction: row;
		margin-bottom : 60px;
	}
}

.EmployeeDetails_TitleWrapperWrapper__2HF1o {
	margin-bottom : 26px;
}

.EmployeeDetails_TitleWrapperWrapper_Title__rhVhD {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EmployeeDetails_ButtonGhostWrapperWrapper__3E2pR {
	width:100%;
}

@media screen and (min-width:645px) {
	.EmployeeDetails_ButtonGhostWrapperWrapper__3E2pR {
		width:147px;
		position: absolute;
		right: 0;
		top:-10px;
	}
}

.EmployeeDetails_ButtonGhost__rjeXa {
	width:100%;
	min-width : 137px;
}

.EmployeeDetails_ButtonEditBankDetailsWrapper__QKJQS{
	text-align: right;
	padding: 22px 0;
}

.EmployeeDetails_ButtonEditBankDetails__3nHue{
	width : auto;
}

.EmployeeDetails_Item__Zf-an{
	padding: 22px 0;
    border-bottom: 1px solid #DCE2EA;
}

.EmployeeDetails_Footer__87uez{
	flex-direction: column;
	margin-top:20px;
}

.EmployeeDetails_FooterColumnFullWidth__23XQ7{
	flex-basis: 100%;
}

.EmployeeDetails_FooterTitle__18Foo{
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	margin-bottom: 13px;
}
.EmployeeDetailItem_EmployeeDetailItem__2Axfc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 0;
    border-bottom: 1px solid #DCE2EA;
}

.EmployeeDetailItem_EmployeeDetailItem__2Axfc:last-child{
    border-bottom: none;
}

.EmployeeDetailItem_EmployeeDetailItem_Title__3lUMA{
    font-size : 17px;
	line-height : 20px;
	color : #6A6D76;
}

.EmployeeDetailItem_EmployeeDetailItem_Value__IatAj{
    font-size : 17px;
	line-height : 20px;
    color : #6A6D76;
    text-align: right;
}

.EmployeeDetailItem_EmployeeDetailItem_Pill__3Xl0n{
    border: 1px solid #D8DDEC;
    border-radius: 22px;
    padding: 7px 13px;
    font-size: 14px;
}

@media screen and (max-width:645px) {

    .EmployeeDetailItem_EmployeeDetailItem__2Axfc{
        flex-direction: column;
        align-items: flex-start;
    }

    .EmployeeDetailItem_EmployeeDetailItem__2Axfc p + p{
        margin-top: 16px;
    }

    .EmployeeDetailItem_EmployeeDetailItem_Title__3lUMA{
        color : #1D1764;
    }

    .EmployeeDetailItem_EmployeeDetailItem_Value__IatAj{
        text-align: left;
    }
}
.EmployeeBankDetailsModal_ModalEditUser__295x- {
	background-color : #2B2B2B;
	text-align : left;
}

.EmployeeBankDetailsModal_ModalWindow__lAwKR {
	background-color : #FFFFFF;
	border-radius : 8px;
}

.EmployeeBankDetailsModal_ModalTitle__1MzwD {
	text-align : center;
}

.EmployeeBankDetailsModal_EditUser_H2__3r2-G {
	color : #1D1764;
}

.EmployeeBankDetailsModal_FormWrapper__1YT76 {
	text-align : left;
	display : block;
}

.EmployeeBankDetailsModal_InputWrapper__ED01c {
	text-align : left;
	display : block;
	padding : 0px 30px 0px 30px;
}

.EmployeeBankDetailsModal_SelectInputWrapper__1la08 {
	text-align : left;
	display : block;
	margin-bottom: 15px;
	padding : 0px 30px 0px 30px;
}

.EmployeeBankDetailsModal_ButtonWrapper__2jO0a {
	text-align : right;
	display : block;
	padding : 0px 30px 30px 30px;
}

.EmployeeBankDetailsModal_ButtonPrimary__3dBYp {
	min-width : 137px;
}

.EmployeeBankDetailsModal_Error__AbLSo{
	color: red;
}


.EmployeeBankDetailsModal_SortcodeInputWrapper__10LIt{
	text-align : left;
	display : block;
	padding : 0px 30px 0px 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 20px;
}

.EmployeeBankDetailsModal_SortcodeInput__2r289{
	width: 30%;
}

.WorkingDays_WorkingDays__VDsWw{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #DFE3E8;
    border-left: 1px solid #DFE3E8;
    margin-bottom: 15px;
}
.WorkingDays_WorkingDaysButton__3E-4f{
    font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #DFE3E8;
    border-bottom: 1px solid #DFE3E8;
    background-color: white;
}

.WorkingDays_WorkingDaysButton__3E-4f:disabled{
    cursor: default;
}

.WorkingDays_WorkingDaysButtonSelected__3lHMU{
    font-size: 15px;
    line-height: 18px;
    color: #FFF;
    background-color: #1C1764;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #1C1764;
    border-bottom: 1px solid #1C1764;
}

.WorkingDays_WorkingDaysButtonSelected__3lHMU:disabled{
    cursor: default;
}

.WorkingDays_WorkingDays_Label__3glTw {
    cursor : pointer;
    font-size : 15px;
    line-height : 18px;
    color : #6A6D76;
    display : block;
    margin-bottom : 8px;
}
.AddEmployee_Header__1-zo4 {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.AddEmployee_Content__1K8zd {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
    padding-top : 40px;
    padding-bottom: 40px;
}

.AddEmployee_Row__1FDgX {
	margin-bottom : 60px;
}

.AddEmployee_Details__2W0ed{
	margin-bottom: 80px;
}

.AddEmployee_HeaderTitleWrapper__2ww3h {
	margin-bottom : 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.AddEmployee_InputWrapper__1ekGq {
	text-align : left;
	display : block;
}

.AddEmployee_InputCheckbox__1psXl {
	margin-bottom: 16px;
}

.AddEmployee_SelectInputWrapper__ZWP_3 {
	text-align : left;
	display : block;
	margin-bottom: 15px;
}

.AddEmployee_FormWrapper__7GQ8Y {
	text-align : left;
	display : block;
}

.AddEmployee_ButtonWrapper__SJn2B {
	text-align : right;
	display : block;
}

.AddEmployee_ButtonGhost__3RVMm {
	min-width : 137px;
}

.AddEmployee_ErrorWrapper__NJOsu{
	text-align: right;
	margin:10px 0;
}

.AddEmployee_Error__if6R7{
	color: red;
	
}

@media screen and (min-width:645px) {
	.AddEmployee_HeaderTitleWrapper__2ww3h {
		flex-direction: row;
	}
}

.AddEmployee_TitleWrapperWrapper__3KV-s {
	margin-bottom : 26px;
}

.AddEmployee_TitleWrapperWrapper_Title__1xiWh {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.AddEmployee_ButtonGhostWrapperWrapper__2IGgG {
	width:100%;
}

@media screen and (min-width:645px) {
	.AddEmployee_ButtonGhostWrapperWrapper__2IGgG {
		width:137px;
		position: absolute;
		right: 15px;
		top:-10px;
	}
}

.AddEmployee_ButtonGhost__3RVMm {
	min-width : 137px;
}

.AddEmployee_WorkingDaysWrapper__3LdSX {
	background-color: white;
}
.AddEmployee_Footer__1Fk0_{
	display: flex;
	flex-direction: row;
}
.AddEmployee_FooterColumnFullWidth__1siOm{
	flex-basis: 100%;
}

.AddEmployee_DocumentRow__2zfRp {
	margin-top:15px;
}

.AddEmployee_ButtonTertiary__2xM37{
	width:100%;
	margin-right: 0px;
	margin-bottom: 12px;
}
.EditEmployee_Header__1Qt-w {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.EditEmployee_Content__df2so {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
    padding-top : 40px;
    padding-bottom: 40px;
}

.EditEmployee_Row__2jFW3 {
	margin-bottom : 60px;
}

.EditEmployee_Details__QUuyt{
	margin-bottom: 80px;
}

.EditEmployee_HeaderTitleWrapper___fwpK {
	margin-bottom : 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.EditEmployee_InputWrapper__1dQRD {
	text-align : left;
	display : block;
}

.EditEmployee_SelectInputWrapper__2c6nj {
	text-align : left;
	display : block;
	margin-bottom: 15px;
}

.EditEmployee_FormWrapper__LT3st {
	text-align : left;
	display : block;
}

.EditEmployee_InputCheckbox__2u0Lz {
	margin-bottom: 16px;
}

.EditEmployee_ButtonWrapper__E0L_D {
	text-align : right;
	display : block;
	padding-top : 30px;
}

.EditEmployee_ButtonPrimary__2yVfy {
	min-width : 137px;
}

.EditEmployee_ErrorWrapper__3ZG3l{
	text-align: right;
	margin:10px 0;
}

.EditEmployee_Error__2Mo48{
	color: red;
}

@media screen and (min-width:645px) {
	.EditEmployee_HeaderTitleWrapper___fwpK {
		flex-direction: row;
		margin-bottom : 60px;
	}
}

.EditEmployee_TitleWrapperWrapper__3MalC {
	margin-bottom : 26px;
}

.EditEmployee_TitleWrapperWrapper_Title__myTtt {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EditEmployee_ButtonPrimaryWrapperWrapper__1ofhq {
	width:100%;
}

@media screen and (min-width:645px) {
	.EditEmployee_ButtonPrimaryWrapperWrapper__1ofhq {
		width:137px;
		position: absolute;
		right: 15px;
		top:-10px;
	}
}

.EditEmployee_ButtonPrimary__2yVfy {
	min-width : 137px;
}

.EditEmployee_Footer__yUYnh{
	display: flex;
	flex-direction: row;
}
.EditEmployee_FooterColumnFullWidth__214wK{
	flex-basis: 100%;
}

.EditEmployee_DocumentRow__2l9rh {
	margin-top:15px;
}

.EditEmployee_ButtonTertiary__143wG{
	width:100%;
	margin-right: 0px;
	margin-bottom: 12px;
}
.FormSelect_Select_Label__PuRgy {
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.FormSelect_Select_Wrapper__1n2Q6 {
	overflow : hidden;
	position : relative;
}

.FormSelect_Select_Select__1zfa- {
	font-size : 16px;
	line-height : 19px;
	color : #6A6D76;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	border-radius : 3px;
	-webkit-appearance : none;
	   -moz-appearance : none;
	        appearance : none;
	width : 100%;
	height : auto;
	margin-bottom : 0;
	padding : 17px 0 0px 0px;
}

.FormSelect_Select_Icon__3koUO {
	position : absolute;
	top : 50%;
	right : 0px;
	transform : translateY(-50%);
	pointer-events : none;
}


.ReinstateEmployeeWarningModal_ButtonWrapper__5kq1f {
    margin-top: 22px;
    text-align: right;
}

.ReinstateEmployeeWarningModal_PrimaryButton__3MdVA {
    margin-left: 22px;
}
.P_P__3U9U4 {
	font-size: 16px;
    line-height: 22px;
    color: #6A6D76;
    margin-bottom: 14px;
}


.PayrollSummary_Header__1Y56K {
	text-align : left;
	display : block;
}

.PayrollSummary_Content__1UASk {
	
	position: relative;
}

.PayrollSummary_ContentWrapper__3Kg1g{
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
	margin: 0 1em 40px;
}

.PayrollSummary_Row__3Fbrf {
	margin-bottom : 26px;
	text-align : left;
}

.PayrollSummary_TitleWrapperWrapper__115ox {
	text-align : center;
}

.PayrollSummary_TitleWrapperWrapper_Title__yLOoH {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.PayrollSummary_TitleWrapperWrapper_SubTitle__CGKOB {
	font-size : 14px;
	line-height : 25px;
	color : #1D1764;
	margin-top: 4px;
}


@media screen and (min-width:1024px) {
	.PayrollSummary_TitleWrapperWrapper__115ox {
		margin-bottom: 20px;
	}
}


.PayrollSummaryList_ListHeaderWrapper__MKozS{
	display: none;
	padding: 10px;
	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}
.PayrollSummaryList_MobileListHeaderWrapper__47WkL{
	padding: 10px;
	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}
.PayrollSummaryList_HeaderText__1_gEv{
	line-height: 16px;
}

.PayrollSummaryList_ButtonWrapper__3skZ_{
	text-align: right;
	margin-bottom:20px;
}

.PayrollSummaryList_ButtonWrapperTop__1uU_Y{
	text-align: right;
}


.PayrollSummaryList_ButtonWrapperTop__1uU_Y Button:last-child{
	margin-left: 20px;
}


.PayrollSummaryList_ButtonWrapper__3skZ_ Button:last-child{
	margin-left: 20px;
}

.PayrollSummaryList_Error__3vTm8{
	text-align: right;
	color: red;
	margin-bottom: 20px;
}

.PayrollSummaryList_PaymentDeductionAmount__2h6Ls{
	text-align: right;
}

.PayrollSummaryList_LoadingWrapper__LjzOA{
	margin-top:	30vh;
}

.PayrollSummaryList_EditPayrollWrapper__bnpac{
	background-color: #e4e9f6;
	width: 100%;
	text-align: right;
	padding: 24px;
	position: fixed;
	bottom: -110px;
	left: 0;
	transition: all 0.5s;
}

.PayrollSummaryList_EditActive__24f-6{
	bottom: 0;
}

.PayrollSummaryList_SearchSortWrapper__1AKvb{
	margin-bottom: 20px;
}

@media screen and (min-width:1024px) {
	.PayrollSummaryList_SearchSortWrapper__1AKvb{
		margin-bottom: 0px;
	}
}


@media screen and (min-width:1024px) {
	.PayrollSummaryList_ListHeaderWrapper__MKozS{
		display: block;
	}

	.PayrollSummaryList_MobileListHeaderWrapper__47WkL{
		display: none;
	}

	.PayrollSummaryList_ButtonWrapperTop__1uU_Y{
		position: absolute;
		right: 0;
		top: 0px;
	}
}

@media print {
	.PayrollSummaryList_ListHeaderWrapper__MKozS{
		display: none;
	}

	.PayrollSummaryList_MobileListHeaderWrapper__47WkL{
		display: none;
	}

	.PayrollSummaryList_ButtonWrapper__3skZ_{
		display: none;
	}

	.PayrollSummaryList_ButtonWrapperTop__1uU_Y{
		display: none;
	}

	.PayrollSummaryList_EditPayrollWrapper__bnpac{
		display: none;
	}

	.PayrollSummaryList_HideInPrint__1VFQ7{
		display: none;
	}
}
.PayrollSummaryListItem_ItemWrapper__30XCC{
	margin-bottom: 20px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;

	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}

.PayrollSummaryListItem_DetailsWrapper__1UYnq{
	padding: 10px;
}

.PayrollSummaryListItem_TotalWrapper__24ZO5{
	text-align: right;
	border-top: 1px solid #EBEBEB;
	padding: 10px;
}

.PayrollSummaryListItem_AdditionalItem__2fong{
	background-color: #f7f9ff;
	border: 1px solid #EBEBEB;
	border-radius: 20px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	padding: 2px 5px;
	display: inline-block;
	vertical-align:top;
    margin-right: 4px;
	margin-bottom: 4px;
	font-size: 14px;
}

.PayrollSummaryListItem_AdditionalItemText__1e5QN{
	font-size: 14px;
	line-height: 16px;
}

.PayrollSummaryListItem_AdditionalItemsList__3xvHV {
	list-style-type: none;
	margin:0;
	padding:0;
}

.PayrollSummaryListItem_PaymentDeductionWrapper__jgfP3{
	margin-bottom: 12px;;
}

.PayrollSummaryListItem_Plus__1Vblx{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}
.PayrollSummaryListItem_Negative__3yOg6{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}

.PayrollSummaryListItem_TotalsPlus__17kpz{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}
.PayrollSummaryListItem_TotalsNegative__HXqXV{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}


.PayrollSummaryListItem_Name__2c2jL{
	display: block;
	border-bottom: 1px solid #6A6D76;
	cursor: pointer;
	line-height: 16px;;
	color: #6A6D76;
	margin-top: 12px;
}

.PayrollSummaryListItem_NiNumber__3M7ik{
	margin-top: 4px;
}

.PayrollSummaryListItem_AdditionalWrapper__27omO{
	min-height: 1px;
	margin: 12px 0;
	
}

.PayrollSummaryListItem_NoMargin__3C8r7{
	margin: 0 !important;
}

.PayrollSummaryListItem_PaymentDeductionAmount__1au71{
	text-align: right;
}



@media screen and (min-width:1024px) {
	.PayrollSummaryListItem_AdditionalWrapper__27omO{
		margin: 0;
		padding: 0 20px 0 0 !important;
	}

	.PayrollSummaryListItem_NoPadding___BdpT{
		padding: 0 !important;
	}
	.PayrollSummaryListItem_Name__2c2jL{
		margin-top: 0px;
	}
}


@media print {
	.PayrollSummaryListItem_ItemWrapper__30XCC{
		border: 1px solid #EBEBEB;
		margin-bottom: 40px;
		font-size: 12px;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		        break-inside: avoid;
	}

	.PayrollSummaryListItem_Plus__1Vblx{
		font-size: 12px;
	}

	.PayrollSummaryListItem_Negative__3yOg6{
		font-size: 12px;
	}

	.PayrollSummaryListItem_Name__2c2jL{
		margin-top: 0px;
		border: none;
	}

	.PayrollSummaryListItem_PaymentDeductionCol__3BurK{
		width: 35%;
	}

	.PayrollSummaryListItem_AdditionalWrapper__27omO{
		margin: 0;
		padding: 0 0.4rem;
	}

	.PayrollSummaryListItem_CheckboxWrapper__Kwzxw{
		width:0%;
	}

	.PayrollSummaryListItem_NameWrapper__34nnW{
		width: 100%;
	}

}
.PayrollSubmissionConfirmationModal_ModalWindow__3Rw1T {
	background-color : #FFFFFF;
	border-radius : 8px;
	text-align : left;
}

.PayrollSubmissionConfirmationModal_ModalTitle__uNJp5 {
	text-align : center;
	display : block;
}

.PayrollSubmissionConfirmationModal_ButtonWrapper__2t_sn {
	text-align : right;
	display : block;
	margin-top:20px;
}

.PayrollSubmissionConfirmationModal_ButtonPrimary__eJxd7 {
	min-width : 159px;
}

.PayrollSubmissionConfirmationModal_ConfirmationLabel__xn2lK {
	font-weight: bold;
}

