.Dialog {
	position: relative;
	width: 706px;
	background-color : #FFFFFF;
	border-radius : 8px;
	padding: 20px 40px;
}

.DialogTicket {
	width: 1410px;
	padding-top: 30px;
}

.DialogBid {
	width: 1012px;
}

.Dialog h2 {
	text-align: center;
	margin-bottom: 2em;
}

.Close {
	position: absolute;
	top: 12px;
	right: 12px;
}

.Dialog .SecondaryButton {
	width: 100%;
	margin-top: 10px;
}
