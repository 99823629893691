.ButtonTertiary {
	text-align : center;
	border : #B4AFE1 solid 2px;
	border-radius : 3px;
	padding : 12px;
	font-size : 14px;
	line-height : 15px;
	color : #6A6D76;
	cursor : pointer;
}
