.Row {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
	margin-bottom: 16px;
	width:100%;
}
.TextColumnWrapperWrapper {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.ButtonColumnWrapperWrapper{
	padding : 8px 0px;
}

.ButtonTertiaryColumnWrapperWrapper{
	padding : 6px 0px;
}

.TextColumn1WrapperWrapper {
	text-align : left;
	padding : 17px 0px 16px 0px;
}

.TextColumnWrapper_Text {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.TextColumnWrapper_TextUnderlined {
	border-bottom:1px solid
}


.Row_FlexWrapper{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
}

.PayPeriodWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
}
.PaymentDateWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
}
.StatusWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
}


.OpenPayroll_Btn{
	width: 100%;
}

.CopyPayrollButton{
	font-size : 14px;
	line-height : 15px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.ButtonPrimary{
	min-width: 106px;
}

.ButtonTertiary{
	min-width: 106px;
}


@media print, screen and (max-width: 50em){
	.PayPeriodWrapperWrapper_FlexWrapper{
		flex-basis: 50%;
	}
	.PaymentDateWrapperWrapper_FlexWrapper{
		display: none;
	}

	.StatusWrapperWrapper_FlexWrapper{
		flex-basis: 50%;
	}


	.ButtonPrimary{
		min-width: 73px;
	}

	.ButtonTertiary{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 73px;
		max-width: 73px;
		display: block;
	}
}

