.Header {
	background-color : #1D1764
}

.Row {
	text-align : left;
}

.LogoWrapperWrapper {
	padding : 16px;
	cursor: pointer;
	display: flex;
    align-items: center;
}

.AvatarWrapper {
	text-align : right;
	padding : 16px 12px 16px 12px;
}
.MenuWrapper{
	position: relative;
}

.DropdownWrapper{
	width:176px;
	z-index: 999999;
	position: absolute;
	right: 10px;
    top: 64px;
}

@media print {
	.Header{
		display: none;
	}
}


.ClientCompanyName{
	color: white;
	margin-left: 20px;
	font-size: 14px;
	line-height: 18px;;
	width: 200px;
}