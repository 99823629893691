.Header {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
    padding-top : 40px;
    padding-bottom: 40px;
}

.Row {
	margin-bottom : 60px;
}

.Details{
	margin-bottom: 80px;
}

.HeaderTitleWrapper {
	margin-bottom : 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.InputWrapper {
	text-align : left;
	display : block;
}

.SelectInputWrapper {
	text-align : left;
	display : block;
	margin-bottom: 15px;
}

.FormWrapper {
	text-align : left;
	display : block;
}

.InputCheckbox {
	margin-bottom: 16px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	padding-top : 30px;
}

.ButtonPrimary {
	min-width : 137px;
}

.ErrorWrapper{
	text-align: right;
	margin:10px 0;
}

.Error{
	color: red;
}

@media screen and (min-width:645px) {
	.HeaderTitleWrapper {
		flex-direction: row;
		margin-bottom : 60px;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonPrimaryWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonPrimaryWrapperWrapper {
		width:137px;
		position: absolute;
		right: 15px;
		top:-10px;
	}
}

.ButtonPrimary {
	min-width : 137px;
}

.Footer{
	display: flex;
	flex-direction: row;
}
.FooterColumnFullWidth{
	flex-basis: 100%;
}

.DocumentRow {
	margin-top:15px;
}

.ButtonTertiary{
	width:100%;
	margin-right: 0px;
	margin-bottom: 12px;
}