.Header {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 40px;
}

.Row {
	margin-bottom : 60px;
}

.Details{
	margin-bottom: 80px;
}

.HeaderTitleWrapper {
	margin-bottom : 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.HeaderTitleWrapper {
		flex-direction: row;
		margin-bottom : 60px;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhostWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonGhostWrapperWrapper {
		width:147px;
		position: absolute;
		right: 0;
		top:-10px;
	}
}

.ButtonGhost {
	width:100%;
	min-width : 137px;
}

.ButtonEditBankDetailsWrapper{
	text-align: right;
	padding: 22px 0;
}

.ButtonEditBankDetails{
	width : auto;
}

.Item{
	padding: 22px 0;
    border-bottom: 1px solid #DCE2EA;
}

.Footer{
	flex-direction: column;
	margin-top:20px;
}

.FooterColumnFullWidth{
	flex-basis: 100%;
}

.FooterTitle{
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	margin-bottom: 13px;
}