.WorkingDays{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #DFE3E8;
    border-left: 1px solid #DFE3E8;
    margin-bottom: 15px;
}
.WorkingDaysButton{
    font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #DFE3E8;
    border-bottom: 1px solid #DFE3E8;
    background-color: white;
}

.WorkingDaysButton:disabled{
    cursor: default;
}

.WorkingDaysButtonSelected{
    font-size: 15px;
    line-height: 18px;
    color: #FFF;
    background-color: #1C1764;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #1C1764;
    border-bottom: 1px solid #1C1764;
}

.WorkingDaysButtonSelected:disabled{
    cursor: default;
}

.WorkingDays_Label {
    cursor : pointer;
    font-size : 15px;
    line-height : 18px;
    color : #6A6D76;
    display : block;
    margin-bottom : 8px;
}