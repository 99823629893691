.SearchResultItem{
    padding:17px;
    border-bottom: 1px solid #b7bdc6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.SearchResultItem:hover {
    background-color: #3B3670;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.SearchResultItem:last-of-type{
    border-bottom: none;
}

.SearchResultItemName{
    color: #FFFFFF;
    font-size: 17px;	
    font-weight: 500;	
    line-height: 19px;
    margin: 0;
    flex-basis: 20%;
}

.SearchResultItemLocation{
    color: #B7BDC6;
    font-size: 15px;	
    font-weight: 500;	
    line-height: 19px;
    margin: 0;
}

.SearchResultLabel{
    color: #B7BDC6;
    font-size: 15px;	
    font-weight: 600;	
    line-height: 23px;
}

.SearchResultValue{
    color: #3C4D67;	
    font-size: 24px;	
    line-height: 35px;
}

.SearchResultItemAboutYou{
    color: #797F8E;
    font-size: 15px;	
    line-height: 23px;
    margin: 0;
    flex-basis: 50%;
}
.SearchResultVertAlignBaseline{
    align-self: baseline
}