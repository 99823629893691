
.LoadingIndicator {
	margin-top: 50px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
	background-color: #00D99F;
	border-radius: 50%;
  }

  .LoadingIndicator:after {
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	border: 0px solid #F7F9FF;
	transform: translate(-50%, -50%);
	animation: loading 900ms ease-out forwards infinite;
  }
  
  @keyframes loading {
	0% {
	  border: 0px solid #F7F9FF;
	}
	
	20% {
	  border: 8px solid #F7F9FF;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #F7F9FF;
	  width: 110%;
	  height: 110%;
	}
  }

  .LoadingIndicatorModal {
	margin-top: 50px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40px;
	height: 40px;
	background-color: #00D99F;
	border-radius: 50%;
  }

  .LoadingIndicatorModal:after {
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	border: 0px solid #AEAFB3;
	transform: translate(-50%, -50%);
	animation: loadingModal 900ms ease-out forwards infinite;
  }
  
  @keyframes loadingModal {
	0% {
	  border: 0px solid #AEAFB3;
	}
	
	20% {
	  border: 8px solid #AEAFB3;
	  width: 0%;
	  height: 0%;
	}
	
	100% {
	  border: 8px solid #AEAFB3;
	  width: 110%;
	  height: 110%;
	}
  }