.InputText_Input {
	width : 100%;
	height : auto;
	border : #fff solid 1px;
	background-color : #FFFFFF;
	box-shadow : none;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 19px 13px;
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	margin: 0;
	appearance: textfield;
}
.InputText_Input::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
  }

.InputText_Input:focus{
	border-color: #00D99F;
	box-shadow : none;
}

.InputText_Input_Currency{
	padding-left: 29px;
}

.Currency{
	position: absolute;
	top:20px;
	left:16px;
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

