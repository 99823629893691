.ItemWrapper{
	margin-bottom: 20px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;

	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}

.DetailsWrapper{
	padding: 10px;
}

.TotalWrapper{
	text-align: right;
	border-top: 1px solid #EBEBEB;
	padding: 10px;
}

.AdditionalItem{
	background-color: #f7f9ff;
	border: 1px solid #EBEBEB;
	border-radius: 20px;
	width: max-content;
	padding: 2px 5px;
	display: inline-block;
	vertical-align:top;
    margin-right: 4px;
	margin-bottom: 4px;
	font-size: 14px;
}

.AdditionalItemText{
	font-size: 14px;
	line-height: 16px;
}

.AdditionalItemsList {
	list-style-type: none;
	margin:0;
	padding:0;
}

.PaymentDeductionWrapper{
	margin-bottom: 12px;;
}

.Plus{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}
.Negative{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}

.TotalsPlus{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}
.TotalsNegative{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}


.Name{
	display: block;
	border-bottom: 1px solid #6A6D76;
	cursor: pointer;
	line-height: 16px;;
	color: #6A6D76;
	margin-top: 12px;
}

.NiNumber{
	margin-top: 4px;
}

.AdditionalWrapper{
	min-height: 1px;
	margin: 12px 0;
	
}

.NoMargin{
	margin: 0 !important;
}

.PaymentDeductionAmount{
	text-align: right;
}



@media screen and (min-width:1024px) {
	.AdditionalWrapper{
		margin: 0;
		padding: 0 20px 0 0 !important;
	}

	.NoPadding{
		padding: 0 !important;
	}
	.Name{
		margin-top: 0px;
	}
}


@media print {
	.ItemWrapper{
		border: 1px solid #EBEBEB;
		margin-bottom: 40px;
		font-size: 12px;
		break-inside: avoid;
	}

	.Plus{
		font-size: 12px;
	}

	.Negative{
		font-size: 12px;
	}

	.Name{
		margin-top: 0px;
		border: none;
	}

	.PaymentDeductionCol{
		width: 35%;
	}

	.AdditionalWrapper{
		margin: 0;
		padding: 0 0.4rem;
	}

	.CheckboxWrapper{
		width:0%;
	}

	.NameWrapper{
		width: 100%;
	}

}