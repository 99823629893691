.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
}

.Row {
	margin-bottom : 26px;
	text-align : left;
}

.TitleWrapperWrapper {
	text-align : center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EmployeesWrapperWrapper {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.EmployeesWrapperWrapper_Employees {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.EmployeesWrapperWrapper_Payroll {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
}

.RectangleWrapperWrapper {
	text-align : left;
	display : block;
}

.SearchWrapperWrapper {
	text-align : left;
}

.ButtonPrimaryWrapperWrapper {
	text-align : right;
	padding-top: 26px;
	padding-left: 16px;
}

.ButtonPrimary {
	min-width : 162px;
	margin-left:16px;
	margin-bottom:17px;
}

.PayrollOfApril30WrapperWrapper {
	text-align : left;
}

.PayrollOfApril30WrapperWrapper_PayrollOfApril30 {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
}

.SearchResultsHeader {
	text-align : left;
	margin-bottom : 11px;
}

.NameWrapperWrapper {
	text-align : left;
	margin-left: 16px;
}

.NameWrapperWrapper_Name {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.PayrollNoWrapperWrapper {
	text-align : left;
}

.PayrollNoWrapperWrapper_PayrollNo {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DepartmentWrapperWrapper {
	text-align : left;
	margin-left: 16px;
}

.DepartmentWrapperWrapper_Department {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.NiNumberWrapperWrapper {
	text-align : left;
}

.NiNumberWrapperWrapper_NiNumber {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.StatusWrapperWrapper {
	text-align : left;
	margin-left: 16px;
}

.StatusWrapperWrapper_Status {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.SearchResults {
	display : block;
	width:100%;
	cursor: pointer;
	margin-bottom: 16px;
}
.SearchWrapper{
	display: flex;
	flex-direction: column;
}

.SearchComponent{
	flex-grow: 2;
}
.LeftEmployeeCheckbox{
	margin: 0 0 29px 16px;
	align-self: flex-end;
}

.LeftEmployeeCheckbox label{
	font-size: 15px;
}

.LeftEmployeeCheckbox span{
	margin-top:0px !important;
}
.LeftEmployeeCheckbox input{
	margin:0 !important;
}

@media print, screen and (min-width: 40em){
	.SearchWrapper{
		flex-direction: row;
		align-items: flex-end;
	}

	.LeftEmployeeCheckbox{
		align-self: auto;
	}
	.LeftEmployeeCheckbox label{
		width: 80px;
		text-align: right;
	}

	.LeftEmployeeCheckbox span{
		margin-top:10px !important;
	}
	.LeftEmployeeCheckbox input{
		margin:10px 0 0 0 !important;
	}
}


@media print, screen and (max-width: 40em){
	.PayrollNoWrapperWrapper{
		text-align: right;
		padding-right:16px;
	}

	.ButtonPrimaryWrapperWrapper{
		padding:0;
	}

	.ButtonPrimary{
		width:100%;
		margin:0;
	}
}
