.SelectWithoutLabel_Wrapper {
	overflow : hidden;
	position : relative;
}

.SelectWithoutLabel_Select {
	font-size : 16px;
	line-height : 21px;
	color : #6A6D76;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	border-radius : 3px;
	appearance : none;
	width : 100%;
	height : auto;
	margin-bottom : 0;
	padding : 17px 0 16px 12px;
}

.SelectWithoutLabel_Select_Slim {
	padding : 12px 0 13px 12px;
}

.SelectWithoutLabel_Icon {
	position : absolute;
	top : 50%;
	right : 12px;
	transform : translateY(-50%);
	pointer-events : none;
}

.SelectWithoutLabel_Select:focus{
	border-color: #00D99F;
}

.SelectWithoutLabel_Label {
	cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
    display: block;
    margin-bottom: 8px;
}


