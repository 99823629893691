.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
}

.Row {
	margin-bottom : 26px;
	text-align : left;
}

.TitleWrapperWrapper {
	text-align : center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EmployeesWrapperWrapper {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.EmployeesWrapperWrapper_Employees {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.EmployeesWrapperWrapper_Payroll {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	display : inline-block;
}

.RectangleWrapperWrapper {
	text-align : left;
	display : block;
}

.SearchWrapperWrapper {
	text-align : left;
}

.ButtonPrimaryWrapperWrapper {
	text-align : right;
	padding-top: 8px;
}

.ButtonPrimary {
	min-width : 162px;
}

.PayrollOfApril30WrapperWrapper {
	text-align : left;
}

.PayrollOfApril30WrapperWrapper_PayrollOfApril30 {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
}

.SearchResultsHeader {
	text-align : left;
	margin-bottom : 11px;
}

.NameWrapperWrapper {
	text-align : left;
}

.NameWrapperWrapper_Name {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.PayrollNoWrapperWrapper {
	text-align : left;
}

.PayrollNoWrapperWrapper_PayrollNo {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.DepartmentWrapperWrapper {
	text-align : left;
}

.DepartmentWrapperWrapper_Department {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.NiNumberWrapperWrapper {
	text-align : left;
}

.NiNumberWrapperWrapper_NiNumber {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.StatusWrapperWrapper {
	text-align : left;
}

.StatusWrapperWrapper_Status {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.SearchResults {
	display : block;
}


@media print, screen and (max-width: 40em){
	.Content {
		padding-top : 30px;
	}
}

