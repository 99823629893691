.Header {
	text-align : left;
	display : block;
}

.Content {
	text-align : left;
	display : block;
}

.ContentTop {
	background-color : #1D1764;
	text-align : left;
	display : block;
	position: relative;
}

.Row {
	text-align : left;
	margin-bottom : 24px;
}

.PayrollHeader{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 69px;
	margin-top: 40px;
}

.PayrollProgress {
	width:170px;
	height:170px;
	margin-right: 24px;
	flex-shrink: 0;
}

.PayrollProgressBar{
	display: none;
	height:8px;
	position: absolute;
	bottom:0;
	left:0;
	width: 100%;
}

.PayrollProgressInnerBar{
	height:8px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	transition: width 0.3s;
}

.stripes {
	color: white;
	background: repeating-linear-gradient(
	  135deg,
	  #2FF9C3,
	  #2FF9C3 5px,
	  #00D99F 5px,
	  #00D99F 10px
	);
  }

.PayrollProgress_Text{
	font-size : 20px;
	line-height : 32px;
	color : #FFFFFF;
}

.PayrollProgress_Text_Bold{
	font-size : 38px;
	line-height : 32px;
	color : #FFFFFF;
}

.PayrollDetails {
	flex-grow: 2;
}

.PayrollDetails_Name {
	font-size : 32px;
	line-height : 49px;
	color : #FFFFFF;
	display : block;
}

.PayrollDetails_Date,
.PayrollDetails_NiNumber {
	font-size : 16px;
	line-height : 27px;
	color : #FFFFFF;
	display : block;
}

.ContentBottom_PayrollDetails{
	display: none;
}
.ContentBottom_PayrollDetails_Name {
	font-size : 27px;
	font-weight : bold;
	line-height : 32px;
	color : #1D1764;
	display : block;
}

.ContentBottom_PayrollDetails_Date {
	font-size : 16px;
	line-height : 27px;
	color : #1D1764;
	display : block;
}

.PayrollNetPay_NetPayLabel {
	text-align : right;
	font-size : 17px;
	line-height : 27px;
	color : #FFFFFF;
	display : block;
}

.PayrollNetPay_NetPayValue {
	text-align : left;
	font-size : 44px;
	line-height : 52px;
	color : #FFFFFF;
	display : block;
}

.ContentBottom {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 55px;
}

.PaymentNameWrapperWrapper {
	text-align : left;
}

.PaymentNameWrapperWrapper_PaymentName {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
}

.HoursNoWrapperWrapper {
	text-align : left;
}

.HoursNoWrapperWrapper_HoursNo {
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
}

.PaymentNameValue {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
}

.HoursWrapperWrapper {
	text-align : left;
	padding: 19px 13px;
}

.HoursWrapperWrapper_Hours {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.HoursValueWrapperWrapper{
	border-left:3px solid #F7F9FF;
	padding: 19px 13px;
}

.ContentBottom_Value {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}
.PaymentDeductionWrapper{
	padding-top: 20px;
	border-top: 1px solid #DAE0EC;
	border-bottom: 1px solid #DAE0EC;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.FormSelectWithoutLabelWrapperWrapper {
	margin-bottom: 20px;
	width:48%;
}

.AddMemoWrapperWrapper {
	display: flex;
	flex-direction: row;
}
.AddMemoWrapperWrapperItem{
	margin-right: 12px;
}

.AddDocumentWrapperWrapperItem{
	margin-right: 20px;
}

.AddMemoWrapperWrapper_AddMemo {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.RemoveMemo{
	margin-left: 11px;
	cursor: pointer;
}

.ContentBottom_AddDocument {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
}
.ButtonWrapper{
	display: flex;
	flex-direction: row-reverse;
}

.ButtonGhost {
	min-width : 137px;
	display : inline-block;
	margin-right : 12px;
}

.ButtonPrimary {
	min-width : 137px;
	display : inline-block;
	
}

.PaymentHoursWrapper{
	margin-bottom: 16px;
}

.DeductionsWrapper{
	margin: 32px 0 16px 0;
}

.Footer{
	display: flex;
	flex-direction: row;
}

.FooterColumn{
	flex-basis: 50%;
	margin-bottom: 20px;
}
.FooterColumnFullWidth{
	flex-basis: 100%;
}

.FooterTitle{
	font-size : 17px;
	line-height : 27px;
	color : #1D1764;
	margin-bottom: 13px;
}

.FooterItem{
	background-color: #fff;
	padding: 17px 13px 16px 13px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-right: 2px solid #F8F9FF;
	margin-bottom: 13px;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
}

.FooterMemoText{
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	cursor: pointer;
}

.FooterItemText{
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.FooterSMPText{
	width:100%;
}

.ButtonTertiary{
	margin-right: 12px;
}


@media print, screen and (max-width: 40em){
	.PayrollHeader{
		flex-direction: column-reverse;
		margin-bottom: 40px;
		align-items: flex-start;
	}
	.PayrollProgress{
		display: none;
	}

	.PayrollProgressBar{
		display: block;
	}

	.PayrollNetPay_NetPayLabel{
		text-align: left;
	}

	.PayrollPay{
		
		width: 100%;
	}

	.ContentBottom{
		padding-top: 24px;
	}

	.ContentBottom_PayrollDetails{
		display: block;
	}

	.PaymentDeductionWrapper{
		flex-direction: column;
	}
	.FormSelectWithoutLabelWrapperWrapper{
		width: 100%;
	}
	.ButtonPrimary{
		width: 48%;
		min-width: 48%;
	}
	.ButtonGhost{
		width: 48%;
		min-width: 48%;
		margin-right : 4%;
	}

	.AddMemoWrapperWrapper{
		flex-direction: column;
	}

	.ButtonTertiary{
		width:100%;
		margin-right: 0px;
		margin-bottom: 12px;
	}

	.Footer{
		flex-direction: column;
	}

}

